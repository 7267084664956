// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{margin:0;font-family:-apple-system,BlinkMacSystemFont,\"Segoe UI\",\"Roboto\",\"Oxygen\",\"Ubuntu\",\"Cantarell\",\"Fira Sans\",\"Droid Sans\",\"Helvetica Neue\",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}[data-rmiz-modal-content]{display:flex;align-items:center;justify-content:center}code{font-family:source-code-pro,Menlo,Monaco,Consolas,\"Courier New\",monospace}.preview--pkbDJ{max-height:50px}.preview--pkbDJ:hover{transform:scale(5);position:absolute;z-index:1}.ellipsis--JwUqI{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA,KACC,QAAA,CACA,mJAAA,CAEA,kCAAA,CACA,iCAAA,CAGD,0BACC,YAAA,CACA,kBAAA,CACA,sBAAA,CAGD,KACC,yEAAA,CAGD,gBACC,eAAA,CAED,sBACC,kBAAA,CACA,iBAAA,CACA,SAAA,CAGD,iBACC,kBAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":["body {\n\tmargin: 0;\n\tfont-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',\n\t\t'Droid Sans', 'Helvetica Neue', sans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\n[data-rmiz-modal-content] {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\ncode {\n\tfont-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n.preview {\n\tmax-height: 50px;\n}\n.preview:hover {\n\ttransform: scale(5);\n\tposition: absolute;\n\tz-index: 1;\n}\n\n.ellipsis {\n\twhite-space: nowrap;\n\ttext-overflow: ellipsis;\n\toverflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": "preview--pkbDJ",
	"ellipsis": "ellipsis--JwUqI"
};
export default ___CSS_LOADER_EXPORT___;
