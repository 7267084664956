// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--C3lHt{display:flex;justify-content:space-between;align-items:end;margin-bottom:10px;gap:20px}.col--R9sJ4{flex-direction:column}", "",{"version":3,"sources":["webpack://./src/pages/admin/PassportDetail/index.module.scss"],"names":[],"mappings":"AAAA,gBACC,YAAA,CACA,6BAAA,CACA,eAAA,CACA,kBAAA,CACA,QAAA,CAGD,YACC,qBAAA","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: end;\n\tmargin-bottom: 10px;\n\tgap: 20px;\n}\n\n.col {\n\tflex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--C3lHt",
	"col": "col--R9sJ4"
};
export default ___CSS_LOADER_EXPORT___;
