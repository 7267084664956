import { useEffect, useState } from 'react';
import useFetch from 'use-http';

const CounterAgent = ({ id }: { id: string }) => {
	const [agentName, setAgentName] = useState('');
	const { get } = useFetch();

	useEffect(() => {
		const fetchData = async () => {
			const data = await get(`/api/example/${id}`);
			setAgentName(data?.title || '');
		};

		fetchData();
	}, []);

	return <>{agentName}</>;
};

export default CounterAgent;
