import React, { ChangeEvent, useState } from 'react';
import useFetch from 'use-http';
import { useNavigate } from 'react-router-dom';
import Paginate from 'components/UI/Paginate';
import usePaginate from 'hooks/usePaginate';
import Table from 'components/UI/Table';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';
import Button from 'components/UI/Button';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { PlacesStatuses, TypeAllocationEnum, getTypeAllocation } from 'types/places';
import { GroupsEnum } from 'types/users';
import { $user } from 'models/user';
import { useUnit } from 'effector-react';
import GetPassport from 'components/GetPassport';
import Checkbox from 'components/UI/Checkbox';
import styles from './index.module.scss';

const perPage = 10;

interface IFormState {
	number: string;
	searchText: string;
	signer: 'all' | 'need-attention';
	onlyAccepted: boolean;
}

export default function Stores() {
	const { get, loading, error, abort } = useFetch();
	const user = useUnit($user);
	const [searchParams, setSearchParams] = useSearchParams();
	const [data, setData] = React.useState([]);
	const { pageCount, offset, setCount, handlePageClick, setOffset } = usePaginate(perPage);
	const currentPage: string | null = searchParams.get('page');
	const [formState, setFormState] = useState<IFormState>({
		number: searchParams.get('number') || '',
		searchText: searchParams.get('searchText') || '',
		signer: (searchParams.get('signer') as 'all' | 'need-attention') || 'all',
		onlyAccepted: searchParams.get('onlyAccepted') === 'true' ? true : false,
	});
	const [isLoading, setIsLoading] = useState(false);
	const setAllSearchParams = React.useCallback(
		(newSearchParams: any) => {
			const result: any = {};

			searchParams.forEach((value, key) => {
				result[key] = value;
			});

			setSearchParams({
				...result,
				...newSearchParams,
			});
		},
		[searchParams],
	);

	React.useEffect(() => {
		if (!offset && currentPage) {
			setOffset((Number(currentPage) - 1) * 10);
		}
	}, []);

	React.useEffect(() => {
		setIsLoading(true);

		const queryParams: string[] = [`offset=${offset}`, `limit=${perPage}`];
		let placeStatus = '';

		if (formState.onlyAccepted) {
			placeStatus = PlacesStatuses.ACCEPTED;
		} else if (formState.signer === 'need-attention') {
			if (user?.groups?.includes(GroupsEnum.SIGNER)) {
				placeStatus = PlacesStatuses.PENDING;
			} else if (user?.groups?.includes(GroupsEnum.ADMIN)) {
				placeStatus = PlacesStatuses.REJECTED;
			}
		}

		if (formState.searchText) {
			queryParams.push(`filter=${formState.searchText}`);
		}

		if (formState.number) {
			queryParams.push(`storeNumber=${formState.number}`);
		}

		if (placeStatus) {
			queryParams.push(`placeStatus=${placeStatus}`);
		}

		const url = `/api/stores?${queryParams.join('&')}`;
		get(url).then(result => {
			setData(result?.rows?.map((row: any) => ({ ...row, uri: row?.preview?.uri ? row.preview.uri : null })) || []);
			setCount(result?.count || 0);
			setAllSearchParams({
				page: String(offset / 10 + 1),
			});

			setIsLoading(false);
		});

		return () => {
			abort();
		};
	}, [offset, perPage, formState]);

	const navigate = useNavigate();

	const headers = [
		{ value: 'number', title: 'Номер' },
		{ value: 'counterpartyTitle', title: 'Контрагент' },
		{ value: 'title', title: 'Название' },
		{ value: 'address', title: 'Адрес' },
		{
			value: 'typeAllocation',
			title: () => {
				return (
					<div>
						Тип
						<br />
						размещения
						<br />
						рекламы
					</div>
				);
			},
			hidden: !!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.ACCOUNTANT) > -1,
			formatted: React.useCallback((row: any) => {
				let message = getTypeAllocation(TypeAllocationEnum.mix);
				if (row?.typeAllocation === TypeAllocationEnum.paid) {
					message = getTypeAllocation(TypeAllocationEnum.paid);
				} else if (row?.typeAllocation === TypeAllocationEnum.free) {
					message = getTypeAllocation(TypeAllocationEnum.free);
				}
				return message;
			}, []),
		},
		{ value: 'manager', title: 'Руководитель' },
		{
			value: 'lastActivity',
			title: () => {
				return (
					<div>
						Последняя
						<br />
						активность
					</div>
				);
			},
			formatted: React.useCallback((row: any) => {
				return row?.lastActivity ? moment(row?.lastActivity).format('DD.MM.YYYY') : <></>;
			}, []),
		},
		{
			value: 'dateOpen',
			title: 'Дата открытия',
			formatted: React.useCallback((row: any) => {
				return row?.dateOpen ? moment(row?.dateOpen).format('DD.MM.YYYY') : <></>;
			}, []),
		},
		{
			value: 'operation',
			title: '',
			width: '300px',
			formatted: React.useCallback(
				(row: any) => {
					const routeChangeOne = () => {
						const path = `/admin/stores/${row.id}/places`;
						navigate(path);
					};
					const routeChangeTwo = () => {
						const path = `/admin/stores/${row.id}/counterparty/${row.counterpartyId}`;
						navigate(path);
					};

					return (
						<div className={`row ${styles.operation}`}>
							<div className={`column-xs-6 ${styles.operation__item}`}>
								<Button size="sm" variant="secondary" onClick={routeChangeOne}>
									Места размещения
								</Button>
							</div>
							<div className={`column-xs-6 ${styles.operation__item}`}>
								{row.counterpartyId ? (
									<Button size="sm" variant="secondary" onClick={routeChangeTwo}>
										Паспорта
									</Button>
								) : (
									user?.groups?.includes(GroupsEnum.ACCOUNTANT) && <GetPassport rowId={row.id} />
								)}
							</div>
						</div>
					);
				},
				[navigate],
			),
		},
	];

	const handleChangeNumber = (event: ChangeEvent<HTMLInputElement>) => {
		setAllSearchParams({
			number: event.target.value,
		});

		setFormState(prevFormState => ({
			...prevFormState,
			number: event.target.value,
		}));
	};

	const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
		setAllSearchParams({
			searchText: event.target.value,
		});

		setFormState(prevFormState => ({
			...prevFormState,
			searchText: event.target.value,
		}));
	};

	const handleChangeMatching = (value: 'all' | 'need-attention') => {
		setAllSearchParams({
			signer: value,
		});

		setFormState(prevFormState => ({
			...prevFormState,
			signer: value,
		}));
	};

	const handleChangeShowOnlyAccepted = (event: ChangeEvent<HTMLInputElement>) => {
		setAllSearchParams({
			onlyAccepted: event.target.checked,
		});

		setFormState(prevFormState => ({
			...prevFormState,
			onlyAccepted: event.target.checked,
		}));
	};

	const options = [
		{
			value: 'all',
			label: 'Все',
		},
		{
			value: 'need-attention',
			label: 'Требуют внимания',
		},
	];

	return (
		<div>
			{error && 'Error!'}
			<div className={`row ${styles.wrapper}`}>
				<div className={`column-md-3 ${styles.input__info}`}>
					<Input
						labelClassName={styles.label}
						onChange={handleChangeNumber}
						name="number"
						label="Поиск только по номеру"
						value={formState.number}
					/>
				</div>
				<div className={`column-md-3 ${styles.input__info}`}>
					<Input
						labelClassName={styles.label}
						onChange={handleChangeSearchText}
						name="all-data"
						label="Поиск по всем данным"
						value={formState.searchText}
					/>
				</div>
				{user?.groups?.includes(GroupsEnum.SIGNER) && (
					<div className={`column-md-3 ${styles.input__info}`}>
						<Select
							isDisabled={formState.onlyAccepted}
							name="signer"
							onChange={handleChangeMatching}
							options={options}
							label="Согласование"
							value={formState.signer}
						/>
					</div>
				)}
				{(user?.groups?.includes(GroupsEnum.SIGNER) || user?.groups?.includes(GroupsEnum.ADMIN)) && (
					<div className={`column-md-3 ${styles.input__info}`}>
						<Checkbox
							labelClassName={styles.label}
							value={formState.onlyAccepted}
							name="accept"
							label="Принятые места"
							disabled={formState.signer === 'need-attention'}
							onChange={handleChangeShowOnlyAccepted}
							checked={formState.onlyAccepted}
						/>
					</div>
				)}
			</div>
			<Table headers={headers} rows={data} isLoading={isLoading} />
			{pageCount > 1 && (
				<Paginate handlePageClick={handlePageClick} pageCount={pageCount} forcePage={Number(currentPage) - 1} />
			)}
		</div>
	);
}
