import Button from 'components/UI/Button';
import React from 'react';
import useFetch from 'use-http';

const GetPassport = ({ rowId }: { rowId: string }) => {
	const { get } = useFetch();
	const [isClicked, setIsClicked] = React.useState<boolean>(false);
	const getPassport = () => {
		const fetchData = async () => {
			await get(`/api/stores/getPassport/${rowId}`);
		};

		fetchData();
		setIsClicked(true);
	};

	return (
		<Button size="sm" variant="secondary" onClick={getPassport} disabled={isClicked}>
			{isClicked ? 'Паспорт запрошен' : 'Запросить паспорт'}
		</Button>
	);
};

export default GetPassport;
