import React from 'react';
import useFetch from 'use-http';
import { useNavigate, useParams } from 'react-router-dom';

import Table from 'components/UI/Table';
import Button from 'components/UI/Button';
import moment from 'moment';

export interface IRejectByUserResponse {
	acceptAccnt: string;
	acceptMrkt: string;
	countBanners: string;
	counterpartyId: string;
	placementAt: string;
}

const getStatus = (data: any, postfix: string) => {
	switch (true) {
		case Number(data['reject' + postfix]) > 0: {
			return 'Не принят';
		}
		case Number(data.countBanners) === Number(data['accept' + postfix]): {
			return 'Принят';
		}
		default: {
			return 'Не просмотрен';
		}
	}
};

export default function Passport() {
	const { storeId, counterpartyId } = useParams();
	const { get, loading, error, response } = useFetch();
	const [data, setData] = React.useState([]);
	const [store, setStore] = React.useState<any>({});
	const [passport, setPassport] = React.useState<any>([]);

	const load = async () => {
		const result = await get(`/api/passport?storeId=${storeId}&counterpartyId=${counterpartyId}`);
		setData(result);

		const storeResult = await get(`/api/stores/${storeId}`);
		setStore(storeResult);

		const passportResult = await get(`/api/passport/rejectByUser?storeId=${storeId}`);
		setPassport(passportResult);
	};

	React.useEffect(() => {
		load();
	}, []);

	const navigate = useNavigate();
	const headers = [
		{ value: 'counterparty.title', title: 'Контрагент' },
		{
			value: 'placementAt',
			title: 'Месяц',
			formatted: React.useCallback(
				(row: any) => new Date(row?.placementAt).toLocaleDateString('ru-ru', { month: 'short', year: 'numeric' }),
				[],
			),
		},
		{
			value: 'status',
			title: 'Статус Бухгалтер',
			formatted: React.useCallback(
				(row: any) => {
					let status = null;
					if (passport.length > 0) {
						status = passport.find((item: any) => {
							if (item.counterpartyId === row['counterparty.id'] && item.placementAt === row['placementAt']) {
								return item;
							}
						});
					}
					return <div>{status && getStatus(status, 'Accnt')}</div>;
				},
				[passport],
			),
		},
		{
			value: 'status',
			title: 'Статус Маркетолог',
			formatted: React.useCallback(
				(row: any) => {
					let status = null;
					if (passport.length > 0) {
						status = passport.find((item: any) => {
							if (item.counterpartyId === row['counterparty.id'] && item.placementAt === row['placementAt']) {
								return item;
							}
						});
					}
					return <div>{status && getStatus(status, 'Mrkt')}</div>;
				},
				[passport],
			),
		},
		{ value: 'comment', title: 'Комментарий' },
		{
			value: 'operation',
			title: '',
			formatted: React.useCallback(
				(row: any) => {
					const routeChange = () => {
						const path = `/admin/stores/${storeId}/passport/${row.id}`;
						navigate(path);
					};
					return (
						<Button size="sm" variant="secondary" onClick={routeChange}>
							Открыть паспорт
						</Button>
					);
				},
				[navigate],
			),
		},
	];

	return (
		<div>
			<h2>Список паспортов в выбраном универмаге</h2>
			<table>
				<tbody>
					<tr>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
							<b>Универмаг</b>
						</td>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>{store?.number}</td>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>{store?.city}</td>
					</tr>
					<tr>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
							<b>Руководитель</b>
						</td>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }} colSpan={2}>
							{store?.manager}
						</td>
					</tr>
					<tr>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
							<b>Дата открытия универмага</b>
						</td>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }} colSpan={2}>
							{store?.dateOpen ? moment(store?.dateOpen).format('YYYY-MM-DD') : ''}
						</td>
					</tr>
					<tr>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
							<b>Адрес универмага</b>
						</td>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }} colSpan={2}>
							{store?.address}
						</td>
					</tr>
					<tr>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
							<b>ТЦ</b>
						</td>
						<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }} colSpan={2}>
							{store.title}
						</td>
					</tr>
				</tbody>
			</table>
			{data && (
				<div>
					{error && 'Error!'}
					{loading && 'Загрузка...'}
					{/* <PassportForm init={load} />  */}

					<Table headers={headers} rows={data} />
				</div>
			)}
		</div>
	);
}
