import React from 'react';
import Camera, { IMAGE_TYPES, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { useFetch } from 'use-http';
import { useParams, useNavigate } from 'react-router-dom';
import { StatusEnum } from 'types/banners';
import classes from 'components/Layout/styles.module.scss';
import { imagebase64 } from 'utils/imagebase64';
import useLocalStorage from 'use-local-storage';
import { IStore } from 'dto/store.dto';

function dataURItoBlob(dataURI: any) {
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
	else byteString = unescape(dataURI.split(',')[1]);

	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i += 1) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
}

const host = process.env.REACT_APP_BACKEND_URL;

function Photo() {
	// TODO refact
	const [store] = useLocalStorage<IStore | undefined>(`store`, undefined);
	const { passportId, placeId } = useParams();
	const { get, post, patch, loading, response, error } = useFetch();

	const navigate = useNavigate();

	const [image, setImage] = React.useState();
	const [place, setPlace] = React.useState<any>(null);
	const [passport, setPassport] = React.useState<any>({});
	const [banner, setBanner] = React.useState<any>(null);

	async function load() {
		const place = await get(`/api/places/${placeId}`);
		setPlace(place);
		const result = await get(`/api/passport/${passportId}`);
		setPassport(result);
		if (place?.id) {
			const bannerList = await get(`/api/banners?passportId=${passportId}&placeId=${place?.id}`);
			if (bannerList?.rows?.length > 0) {
				setBanner(bannerList?.rows[0]);
			}
		}
	}

	React.useEffect(() => {
		if (placeId) {
			load();
		}
	}, [placeId]);

	function handleTakePhoto(dataUri: any) {
		setImage(dataUri);
	}

	const handleSave = async (posted: boolean) => {
		const file = dataURItoBlob(image);
		const body = new FormData();
		body.append('file', file, 'image.jpg');

		const imageRes = await post('api/file/download', body);

		if (!store?.id) {
			alert('Магазин не определен');
		} else {
			const exifRes = await post('api/banners/exif', {
				passportId,
				storeId: store.id,
				fileId: imageRes.id,
			});

			const data = {
				...exifRes,
				passportId,
				posted,
				placeId: place?.id,
				status: StatusEnum.pending,
				statusMrkt: StatusEnum.pending,
				statusAccnt: place.typeAllocation === 'free' ? StatusEnum.accept : StatusEnum.pending,
			};

			await post(`/api/banners/upsert`, data).then(() => navigate(`/places?placementAt=${passport.placementAt}`));
		}
	};

	return (
		<div>
			{image ? (
				<div>
					<div className={classes.main}>
						<div className={classes.confirm}>
							<div className={classes['confirm__pic']}>
								<img alt="img" src={image} style={{ width: 'auto' }} />
							</div>
							<div className={classes['confirm__row']}>
								<button
									onClick={() => handleSave(false)}
									className={`${classes.btn} ${classes.btn} ${classes['btn--attention']}`}
								>
									Реклама Familia <br />
									ОТСУТСТВУЕТ
								</button>
								<button
									onClick={() => handleSave(true)}
									className={`${classes.btn} ${classes.btn} ${classes['btn--primery']}`}
								>
									Реклама Familia <br /> В НАЛИЧИИ
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div>
					<div className={classes.main}>
						<Camera
							imageType={IMAGE_TYPES.JPG}
							isImageMirror={false}
							idealFacingMode={FACING_MODES.ENVIRONMENT}
							onTakePhoto={(dataUri: any) => {
								handleTakePhoto(dataUri);
							}}
						/>
					</div>
					{banner && (
						<div className={classes.main}>
							{banner.statusAccnt === StatusEnum.reject && (
								<div className={`${classes['photolocations-message']}`}>{banner.commentAccnt}</div>
							)}
							{banner.statusMrkt === StatusEnum.reject && (
								<div className={`${classes['photolocations-message']} `}>{banner.commentMrkt}</div>
							)}
						</div>
					)}
				</div>
			)}
			<h3 style={{ textAlign: 'center' }}>Пример фото</h3>
			<footer className={classes.footer}>
				{place && place['preview.uri'] && <img alt="img" src={place['preview.uri']} style={{ width: '100%' }} />}
			</footer>
		</div>
	);
}

export default Photo;
