import classes from '../Header/styles.module.scss';
import Logout from './Logout';
import { VERSION } from 'utils/constants';
import { useUnit } from 'effector-react';
import { $user } from 'models/user';
import { GroupsEnum } from 'types/users';

export default function Topbar() {
	const user = useUnit($user);

	return (
		<div className={`profile-bar__drop ${classes['profile-bar__drop']} ${classes.drop} ${classes['drop--right-pos']}`}>
			<div className={classes.drop__wrap}>
				<div className={classes['user']}>
					<div className={classes['user__wrapper']}>
						<p className={classes['user__firstname']}>{user?.family_name}</p>
						<p className={classes['user__lastname']}>{user?.given_name}</p>
					</div>
					<p className={classes['user__contact']}>{user?.email || user?.preferred_username}</p>
				</div>
				<nav className={classes['drop-nav']}>
					<ul>
						<li>
							{user &&
								Array.isArray(user.groups) &&
								(user?.groups.indexOf(GroupsEnum.ADMIN) > -1 ||
									user?.groups.indexOf(GroupsEnum.ACCOUNTANT) > -1 ||
									user?.groups.indexOf(GroupsEnum.MARKETER) > -1) && <Logout />}
						</li>
						<li>
							<div className={classes['profile-bar__version']}>
								Версия: {VERSION}.{process.env.REACT_APP_CI_COMMIT_SHORT_SHA}
							</div>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
}
