import { FieldError } from 'react-hook-form';
import classes from './styles.module.scss';
import { useId } from 'react';

export default function Input({
	min,
	label,
	onChange,
	onBlur,
	type = 'text',
	size = 'md',
	placeholder = '',
	onFocus,
	error,
	name,
	value,
	withLabel = true,
	defaultValue = null,
	isRequired = false,
	isDisabled = false,
	className = '',
	labelClassName = '',
}: {
	min?: number | string | undefined;
	name: string;
	label?: string;
	onChange?: (...event: any[]) => (void | Promise<void>) | React.ChangeEventHandler<HTMLInputElement> | undefined;
	onBlur?: (...event: any[]) => void | Promise<void>;
	type?: string;
	size?: 'md' | 'sm' | 'lg';
	placeholder?: string;
	onFocus?: (...event: any[]) => void | Promise<void>;
	error?: FieldError | undefined;
	withLabel?: boolean | undefined;
	value?: any;
	defaultValue?: any;
	isRequired?: boolean;
	isDisabled?: boolean;
	className?: string | string[];
	labelClassName?: string | string[];
}) {
	const id: string = useId();

	return (
		<label htmlFor={id} className={withLabel ? classes.label : ''}>
			{label && (
				<span
					className={`${Array.isArray(labelClassName) ? labelClassName.join(' ') : labelClassName} ${
						classes.label__title
					}`}
				>
					{label}
				</span>
			)}
			<input
				id={id}
				autoComplete="off"
				onChange={onChange}
				onBlur={onBlur}
				type={type}
				name={name}
				value={value}
				className={`${Array.isArray(className) ? className.join(' ') : className} ${classes.input} ${
					error ? classes.error : ''
				} ${classes[`input--${size}`]}`}
				min={min}
				placeholder={placeholder}
				// TODO onFocus нужен для InputMask
				onFocus={onFocus}
				defaultValue={defaultValue}
				required={isRequired}
				disabled={isDisabled}
			/>
			{error && <small className={classes.label__error}>{error.message}</small>}
			{/* <small className="label__txt">Текст примечания</small> */}
		</label>
	);
}
