import React from 'react';
import { FieldError } from 'react-hook-form';
import CheckIcon from 'assets/img/svg/check.svg';
import FileDownload from 'assets/img/svg/download.svg';
import classes from '../Button/styles.module.scss';

export default function File({
	onChange,
	size = 'md',
	error,
	name,
	label = 'Выберите файл',
	nowrap = false,
	classNameBtn,
	labelClass,
	buttonText = 'Выберите файл',
	multiple = false,
	noWrapLabel = false,
	id = 'file',
}: {
	name: string;
	onChange: (...event: any[]) => void;
	size?: 'md' | 'sm' | 'lg' | undefined;
	error?: FieldError | undefined;
	label?: string | null;
	nowrap?: boolean;
	classNameBtn?: string | undefined;
	labelClass?: string | undefined;
	buttonText?: string | undefined | null;
	multiple?: boolean;
	noWrapLabel?: boolean;
	id?: string;
}) {
	const [filename, setFilename] = React.useState(buttonText);

	// TODO почему то по умолчанию вызывает onChange с e.target.files
	const handleChange = (e: any) => {
		if (e?.target?.files && e.target.files[0]) {
			setFilename(e.target.files[0]?.name);
		}
		onChange(e.target.files);
	};

	return (
		<label htmlFor={id} className={labelClass && classes['input-file']}>
			{label && (
				<span className={`${classes.label__title} ${noWrapLabel ? classes['label__title_no-wrap'] : ''}`}>{label}</span>
			)}
			<input id={id} type="file" name={name} onChange={handleChange} hidden multiple={multiple} />
			<div
				className={`${classes.btn} ${classes[`btn--${size}`]} ${classes[`btn-primery`]} ${
					nowrap && filename !== 'Выберите файл' && classes[`btn-nowrap`]
				}`}
				// className={`btn btn--${size} btn-primery ${nowrap && filename !== 'Выберите файл' && 'btn-nowrap'}`}
			>
				<i>
					<img
						src={filename && filename !== 'Выберите файл' ? CheckIcon : FileDownload}
						alt=""
						style={{ filter: 'brightness(0) invert(1)' }}
					/>
				</i>
				{buttonText && <span>{filename}</span>}
			</div>
			{error && <small className={classes[`input-file__error`]}>{error.message}</small>}
		</label>
	);
}
