import React, { FormEvent } from 'react';
import Button from 'components/UI/Button';
import styles from './index.module.scss';
import useFetch from 'use-http';
import Input from 'components/UI/Input';

const RequestUpToDateData = ({ storeId }: { storeId: string }) => {
	const { post } = useFetch();
	const [isClicked, setIsClicked] = React.useState<boolean>(false);

	const requestData = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const form = event.currentTarget;
		const formData = new FormData(form);
		const fetchData = async () => {
			await post(`/api/stores/requestUpdate/${storeId}`, {
				comment: formData.get('comment'),
			});
		};

		fetchData();
		setIsClicked(true);
		form.reset();
	};

	return (
		<form onSubmit={requestData}>
			<Button type="submit" size="sm" variant="secondary" propsClasses={styles.button} disabled={isClicked}>
				{isClicked ? 'Данные запрошены' : 'Запросить актуальные данные'}
			</Button>
			<Input name="comment" isRequired placeholder="Комментарий" isDisabled={isClicked} />
		</form>
	);
};

export default RequestUpToDateData;
