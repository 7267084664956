import { getTypeAllocation, PlacesStatuses, TypeAllocationEnum } from 'types/places';
import ZoomComponent from 'components/UI/Zoom';
import informationIcon from 'assets/img/svg/information.svg';
import styles from './index.module.scss';
import { MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import CounterAgent from 'components/CounterAgent/index.module';

interface IProps {
	row: any;
}

const fields = {
	description: 'Краткое описание',
	typeAllocation: 'Тип размещения',
	'example.title': 'Место размещения',
	'counterparty.title': 'Контрагент',
	beginAt: 'Дата начала',
	endAt: 'Дата окончания',
	costAllocation: 'Стоимость размещения',
	docsId: 'Документ',
	previewId: 'Пример фото',
	exampleId: 'Контрагент',
};

const components = {
	before: (row: any) => (
		<div className={styles['td-flex']}>
			{Object.keys(row.changes).map((key: string, index: number) => {
				const reactKey = `before-${key}-${row[key]}-${index}`;

				if (key === 'docId') {
					return (
						<div className={styles.wrapper__col} key={reactKey}>
							<span>{fields[key as keyof typeof fields]}: </span>
							<span className={styles['td-flex__item']}>
								<a href={`/api/file/readById/${row[key]}`} download>
									Документ
								</a>
							</span>
						</div>
					);
				}

				if (['previewId'].includes(key)) {
					return (
						<div className={styles.wrapper__col} key={reactKey}>
							<span>{fields[key as keyof typeof fields]}: </span>
							<ZoomComponent src={`/api/file/${row[key]}`} />
						</div>
					);
				}

				if (key === 'exampleId') {
					return (
						<div className={styles.wrapper__col} key={reactKey}>
							<span>{fields[key as keyof typeof fields]}: </span>
							<span className={`${styles.changes__item} ${styles['td-flex__item']}`}>
								<CounterAgent id={row[key]} key={reactKey} />
							</span>
						</div>
					);
				}

				return (
					<div className={styles.wrapper__col} key={reactKey}>
						<span>{fields[key as keyof typeof fields]}: </span>
						<span className={`${styles.changes__item} ${styles['td-flex__item']}`}>
							{key === 'typeAllocation'
								? getTypeAllocation(TypeAllocationEnum[row[key] as keyof typeof TypeAllocationEnum])
								: row[key] || ' '}
						</span>
					</div>
				);
			})}
		</div>
	),
	after: (row: any) => (
		<div className={styles['td-flex']}>
			{Object.entries(row.changes).map(([key, value]: any, index: number) => {
				const reactKey = `after-${key}-${value}-${index}`;

				if (key === 'docId') {
					return (
						<div className={styles.wrapper__col} key={reactKey}>
							<span className={styles['td-flex__item']}>
								<span>{fields[key as keyof typeof fields]}: </span>
								<a href={`/api/file/readById/${value}`} download>
									Документ
								</a>
							</span>
						</div>
					);
				}

				if (key === 'previewId') {
					return (
						<div className={styles.wrapper__col} key={reactKey}>
							<span>{fields[key as keyof typeof fields]}: </span>
							<ZoomComponent src={`/api/file/${value}`} />
						</div>
					);
				}

				if (key === 'exampleId') {
					return (
						<div className={styles.wrapper__col} key={reactKey}>
							<span>{fields[key as keyof typeof fields]}: </span>
							<span className={`${styles.changes__item} ${styles['td-flex__item']}`}>
								<CounterAgent id={value} key={reactKey} />
							</span>
						</div>
					);
				}

				return (
					<div className={styles.wrapper__col} key={reactKey}>
						<span>{fields[key as keyof typeof fields]}: </span>
						<span className={`${styles.changes__item} ${styles['td-flex__item']}`}>
							{key === 'typeAllocation'
								? getTypeAllocation(TypeAllocationEnum[value as keyof typeof TypeAllocationEnum])
								: value || ' '}
						</span>
					</div>
				);
			})}
		</div>
	),
};

const Changes = ({ row }: IProps) => {
	const [isShowChanges, setIsShowChanges] = useState(false);

	const toggleShowChanges = () => {
		setIsShowChanges(prev => !prev);
	};

	const handleClickOutside = (event: any) => {
		if (!event.target.closest(`.${styles.wrapper}`)) {
			setIsShowChanges(false);
		}
	};

	useEffect(() => {
		if (isShowChanges) {
			document.addEventListener('click', handleClickOutside);
		} else {
			document.removeEventListener('click', handleClickOutside);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [isShowChanges]);

	if (!row.changes && row.status === PlacesStatuses.PENDING) {
		return <span className={styles['td-flex__item']}>Новое РМ</span>;
	}

	if (!row.changes) {
		return null;
	}

	return (
		<div className={styles.changes}>
			{isShowChanges && (
				<div className={styles.wrapper}>
					{components.before(row)}
					{components.after(row)}
				</div>
			)}
			{isShowChanges && <div className={styles.overlay}></div>}
			<button
				type="button"
				onClick={(event: MouseEvent<HTMLButtonElement>) => {
					event.stopPropagation();

					toggleShowChanges();
				}}
				className={`${isShowChanges ? styles.button_active : ''} ${styles.button}`}
			>
				<img src={informationIcon} alt="information" />
			</button>
		</div>
	);
};

export default Changes;
