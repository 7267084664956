import useFetch from 'use-http';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import File from 'components/UI/File';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import React from 'react';
import { CounterPartyType } from 'pages/admin/Counterparty';

export default function CounterpartyForm({
	init,
	setFilter,
	data,
}: {
	init: () => Promise<void>;
	setFilter: React.Dispatch<React.SetStateAction<string>>;
	data: CounterPartyType[];
}) {
	const { handleSubmit, formState, control, watch, setError } = useForm<any>();
	const { errors } = formState;
	const { post, get, loading, error, response } = useFetch();

	React.useEffect(() => {
		setFilter(watch('title'));
	}, [watch('title')]);

	const onSubmit: SubmitHandler<any> = React.useCallback(
		async params => {
			const found = data.map((item: CounterPartyType) => item.title).indexOf(watch('title'));

			if (found > -1) {
				setError('title', { type: 'custom', message: 'Контрагент с таким названием уже существующет' });
			} else {
				if (confirm('Вы уверены, что хотите добавить нового контрагента?')) {
					await post('/api/counterparty', params);
					await init();
				}
			}
		},
		[watch('title'), data],
	);

	return (
		<div>
			{error && 'Error!'}
			{loading && 'Загрузка...'}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="column-md-3">
						<Controller
							name="title"
							control={control}
							rules={{ required: 'Обязательное поле' }}
							render={({ field, fieldState }) => (
								<Input
									onChange={field.onChange}
									onBlur={field.onBlur}
									name={field.name}
									error={fieldState.error}
									label="Название контрагента"
									defaultValue={field.value}
								/>
							)}
						/>
					</div>
					<div className="column-md-2">
						<div style={{ marginTop: '36px' }}>
							<Button style={{ background: 'rgb(83, 195, 92)' }} type="submit" size="md">
								Добавить
							</Button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}
