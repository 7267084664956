// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form--y6Pn9{max-width:1600px}.controller--b5De9{max-width:initial}", "",{"version":3,"sources":["webpack://./src/components/PlaceForm/index.module.scss"],"names":[],"mappings":"AAAA,aACC,gBAAA,CAGD,mBACC,iBAAA","sourcesContent":[".form {\n\tmax-width: 1600px;\n}\n\n.controller {\n\tmax-width: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form--y6Pn9",
	"controller": "controller--b5De9"
};
export default ___CSS_LOADER_EXPORT___;
