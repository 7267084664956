export enum RolesEnum {
	Admin = 'Admin',
	Accountant = 'Accountant', // Бухгалтер
	Retail = 'Retail', // Розница
	Marketer = 'Marketer', // Маркетолог
}

export enum PositionType {
	Office = 'office', // офис
	Market = 'market', // магазин
}

export enum GroupsEnum {
	ADMIN = '/fpo/fpo. администрирование рекламных мест',
	MARKETER = '/fpo/fpo. проверка паспорта (маркетинг)',
	ACCOUNTANT = '/fpo/fpo. проверка паспорта (бухгалтерия)',
	PASSPORT_UPLOAD = '/fpo/fpo. загрузка паспортов',
	REPORT = '/fpo/fpo. отчет по полученным фотоотчетам',
	CLIENT = '/fpo/fpo. общее',
	CANCELER = '/fpo/fpo. отмена проверки (бухгалтерия)',
	SIGNER = '/fpo/fpo. согласование изменений',
	RD1 = '/rdaccess/rdaccess. rd1',
	RD2 = '/rdaccess/rdaccess. rd2',
	RD3 = '/rdaccess/rdaccess. rd3',
	RD4 = '/rdaccess/rdaccess. rd4',
	RD5 = '/rdaccess/rdaccess. rd5',
	RD6 = '/rdaccess/rdaccess. rd6',
	RD7 = '/rdaccess/rdaccess. rd7',
	RD8 = '/rdaccess/rdaccess. rd8',
	RD9 = '/rdaccess/rdaccess. rd9',
	RD10 = '/rdaccess/rdaccess. rd10',
	RD11 = '/rdaccess/rdaccess. rd11',
	RD12 = '/rdaccess/rdaccess. rd12',
}

export interface IPosition {
	createdAt: Date;
	id: string;
	title: string;
	type: PositionType;
	unitId: string;
	updatedAt: Date;
}

export interface IUser {
	id: number;
	firstname: string;
	secondname: string;
	middlename: string;
	createdAt: Date;
	updatedAt: Date;
	position: IPosition;
	birthday?: string | undefined;
	roles: string[];
}
