import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';

import { BrowserRouter } from 'react-router-dom';

const container: HTMLElement | null = document.getElementById('root');

if (container) {
	const root = createRoot(container);
	container.style.height = '100%';

	root.render(
		<BrowserRouter>
			<App />
		</BrowserRouter>,
	);
}
