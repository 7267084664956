import { FieldError } from 'react-hook-form';
import classes from './styles.module.scss';
import { useId } from 'react';

export default function Checkbox({
	label,
	onChange,
	onFocus,
	onBlur,
	value,
	error,
	name,
	disabled = false,
	checked = false,
	labelClassName = '',
}: {
	name?: string | undefined;
	label?: string;
	value?: boolean;
	onChange?: (...event: any[]) => boolean | void | Promise<void>;
	onBlur?: (...event: any[]) => void | Promise<void>;
	onFocus?: (...event: any[]) => void | Promise<void>;
	error?: FieldError | undefined;
	disabled?: boolean;
	checked?: boolean;
	labelClassName?: string | string[];
}) {
	const id = useId();

	return (
		<div className={classes['check']}>
			<span
				className={`${Array.isArray(labelClassName) ? labelClassName.join(' ') : labelClassName} ${
					classes['check__txt']
				}`}
			>
				{label}
			</span>
			<input
				onChange={onChange}
				onBlur={onBlur}
				name={name}
				disabled={disabled}
				type="checkbox"
				id={id}
				checked={value}
			/>
			<label htmlFor={id} className={`${classes['check__custom']} ${classes['check__custom--md']}`}></label>
		</div>
	);
}
