// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".not-found--zw2xO{font-size:20px;padding:20px;text-align:center}", "",{"version":3,"sources":["webpack://./src/components/NotFound/styles.module.scss"],"names":[],"mappings":"AAAA,kBACC,cAAA,CACA,YAAA,CACA,iBAAA","sourcesContent":[".not-found {\n\tfont-size: 20px;\n\tpadding: 20px;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"not-found": "not-found--zw2xO"
};
export default ___CSS_LOADER_EXPORT___;
