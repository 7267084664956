import React from 'react';
import { Route, Routes, Outlet, Navigate, useNavigate } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';
import { useUnit } from 'effector-react';
import { $token, $user } from 'models/user';
import Home from 'pages/Home';
import useFetch, { CachePolicies, Provider } from 'use-http';
import Photo from 'pages/Photo';
import AdminLayout from 'components/AdminLayout';
import Layout from 'components/Layout';
import Stores from 'pages/admin/Stores';
import moment from 'moment';
import { /*$store, */ $storeList, $storeStr /*, setStore*/, setStoreList } from 'models/store';
import { IStore, IStoreItem } from 'dto/store.dto';
import { GroupsEnum } from 'types/users';
import Forbidden from 'components/Forbidden';
import Login from 'components/Login';
import NotFound from 'components/NotFound';
import Places from 'pages/Places';
import SupportForm from 'components/SupportForm';

import Passport from 'pages/admin/Passport';
import PassportList from 'pages/Passport';
import PassportDetail from 'pages/admin/PassportDetail';
import PlacesAdmin from 'pages/admin/Places';
import EditPlace from 'pages/admin/EditPlace';
import ReportPage from 'pages/admin/Report';
import Banners from 'pages/admin/Banners';
import StoreStatuses from 'pages/admin/StoreStatuses';
import StoreStatusesByManager from 'pages/admin/StoreStatusesByManager';
import Counterparty from 'pages/admin/Counterparty';
import Example from 'pages/admin/Example';
import Section from 'pages/Section';
import ConfirmSection from 'pages/Section/ConfirmSection';
import useLocalStorage from 'use-local-storage';
import { isRd } from 'utils/helpers';

moment.locale('ru');

const host = process.env.REACT_APP_BACKEND_URL;

const ProtectedRoute = ({
	isAllowed = false,
	redirectPath = `/login`,
	children,
}: {
	isAllowed?: boolean;
	redirectPath?: string;
	children?: React.ReactNode;
}) => {
	const { keycloak } = useKeycloak();
	const encodedRef = encodeURIComponent(location.pathname);
	const user = useUnit($user);
	const storeStr = useUnit($storeStr);
	const storeList = useUnit($storeList);
	const { get } = useFetch();
	const [store, setStore] = useLocalStorage<IStore | undefined>(`store`, undefined);

	React.useEffect(() => {
		async function loadStore() {
			if (user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.CLIENT) > -1) {
				try {
					// TODO refact
					let storesObject: IStoreItem[] = [];

					if (storeStr) {
						const correctedJsonString = storeStr.replace(/'/g, '"');
						storesObject = JSON.parse(correctedJsonString);
						if (storesObject?.length) {
							const storesList = [];
							for (let i = 0; i < storesObject?.length; i++) {
								if (storesObject[i].storeId) {
									const store = await get(`/api/stores/num/${storesObject[i].storeId}`);
									storesList.push(store);
								}
							}
							setStoreList(storesList);
							const priorityStore = storesObject.find((item: IStoreItem) => +item.position_type === 1);
							if (!store && priorityStore?.storeId) {
								const store = await get(`/api/stores/num/${priorityStore.storeId}`);
								setStore(store);
							}
							if (storesList.length === 0) {
								setStore(undefined);
							}
						}
					}
				} catch (e) {
					console.log(e);
				}
			}
		}
		if (user) {
			loadStore();
		}
	}, [user, storeStr]);

	console.log({ storeList, store, storeStr });

	console.log('ProtectedRoute', { isAllowed, user, redirectPath, authenticated: keycloak.authenticated, encodedRef });
	if (!user) {
		return <Navigate to={redirectPath + `?ref=${encodedRef}`} replace />;
	}
	if (!keycloak?.authenticated) {
		return <Navigate to={redirectPath + `?ref=${encodedRef}`} replace />;
	}
	if (!isAllowed) {
		return <Forbidden />;
	}

	return React.isValidElement(children) ? children : <Outlet />;
};

function AppRouter() {
	const user = useUnit($user);
	const token = useUnit($token);
	const navigate = useNavigate();

	const { initialized } = useKeycloak();

	React.useEffect(() => {
		if (
			user &&
			Array.isArray(user.groups) &&
			(user.groups.indexOf(GroupsEnum.ADMIN) > -1 ||
				user.groups.indexOf(GroupsEnum.ACCOUNTANT) > -1 ||
				user.groups.indexOf(GroupsEnum.MARKETER) > -1) &&
			user.groups.indexOf(GroupsEnum.PASSPORT_UPLOAD) === -1 &&
			!/admin/.test(window.location.pathname)
		) {
			navigate('/admin');
		}
	}, [user]);

	if (!initialized) {
		return <div>Загрузка...</div>;
	}

	const globalOptions = {
		cachePolicy: CachePolicies.NO_CACHE,
		interceptors: {
			request: ({ options }: { options: RequestInit }) => {
				const newOptions = options;
				newOptions.headers = {
					Authorization: `Bearer ${token}`,
					...options.headers,
				};
				return newOptions;
			},
		},
	};

	const allGroups = Object.values(GroupsEnum);

	return (
		<Provider url={host} options={globalOptions}>
			<Routes>
				<Route
					element={
						<ProtectedRoute
							isAllowed={!!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.PASSPORT_UPLOAD) > -1}
						/>
					}
				>
					<Route
						path=""
						element={
							<Layout>
								<Home />
							</Layout>
						}
					/>
					<Route
						path="section"
						element={
							<Layout>
								<Section />
							</Layout>
						}
					/>
					<Route
						path="section/confirm/:storeId"
						element={
							<Layout>
								<ConfirmSection />
							</Layout>
						}
					/>
					<Route
						path="places"
						element={
							<Layout>
								<Places />
							</Layout>
						}
					/>
					<Route
						path="photo/:passportId/:placeId"
						element={
							<Layout>
								<Photo />
							</Layout>
						}
					/>
					<Route
						path="support"
						element={
							<Layout>
								<SupportForm />
							</Layout>
						}
					/>
					<Route
						path="passports"
						element={
							<Layout>
								<PassportList />
							</Layout>
						}
					/>
				</Route>

				<Route
					path="/admin"
					element={
						<ProtectedRoute
							isAllowed={
								!!user &&
								Array.isArray(user.groups) &&
								!!user.groups.length &&
								user.groups.some(group => {
									if (group === GroupsEnum.CLIENT) {
										return false;
									}

									return allGroups.includes(group as GroupsEnum);
								})
							}
						/>
					}
				>
					<Route element={<AdminLayout />}>
						<Route path="" element={<Stores />} />
						<Route path="stores/:emailManager/statuses" element={<StoreStatusesByManager />} />
						<Route path="stores/statuses" element={<StoreStatuses />} />
						<Route path="stores/:storeId/counterparty/:counterpartyId" element={<Passport />} />
						<Route path="stores/:storeId/places" element={<PlacesAdmin />} />
						<Route path="stores/:storeId/passport/:passportId" element={<PassportDetail />} />
						<Route path="banners" element={<Banners />} />
						<Route
							path="report"
							element={
								<ProtectedRoute isAllowed={user?.groups?.includes(GroupsEnum.REPORT) || !isRd(user?.groups)}>
									<ReportPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="places/:id"
							element={
								<ProtectedRoute isAllowed={user?.groups?.includes(GroupsEnum.ADMIN)}>
									<EditPlace />
								</ProtectedRoute>
							}
						/>
						<Route path="directory/example" element={<Example />} />
						<Route path="directory/counterparty" element={<Counterparty />} />
					</Route>
				</Route>

				<Route path="login" element={<Login />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Provider>
	);
}

export default AppRouter;
