import React, { useCallback, type ChangeEvent, type FormEvent } from 'react';
import classes from './index.module.scss';
import { useFetch } from 'use-http';
import { PlacesStatuses } from 'types/places';

interface Props {
	row: any;
	load: () => void;
}

const CoordinationForm = ({ row, load }: Props) => {
	const { post } = useFetch();
	const [isReject, setIsReject] = React.useState<boolean>(false);
	const [reason, setReason] = React.useState<string>('');
	const acceptChanges = useCallback((): void => {
		async function fetch() {
			await post(`/api/places/accept/${row.id}`);
			await load();
		}

		fetch();
	}, []);

	const rejectChanges = useCallback((comment: string) => {
		async function fetch() {
			await post(`/api/places/reject/${row.id}`, {
				comment,
			});
			await load();
		}

		fetch();
	}, []);

	return !isReject ? (
		<div className={classes.coordination}>
			<button
				onClick={() => {
					acceptChanges();
				}}
				className={`${row.status !== PlacesStatuses.PENDING ? classes.coordination__button_disabled : ''} ${
					classes.coordination__button
				} ${classes.coordination__button_accept}`}
				disabled={row.status !== PlacesStatuses.PENDING}
			>
				Принять
			</button>
			<button
				onClick={() => {
					setIsReject(true);
				}}
				className={`${row.status !== PlacesStatuses.PENDING ? classes.coordination__button_disabled : ''} ${
					classes.coordination__button
				} ${classes.coordination__button_reject}`}
				disabled={row.status !== PlacesStatuses.PENDING}
			>
				Отклонить
			</button>
		</div>
	) : (
		<form
			onSubmit={(event: FormEvent<HTMLFormElement>) => {
				const formData = new FormData(event.currentTarget);

				event.preventDefault();
				setIsReject(false);
				rejectChanges(formData.get('reason') as string);
			}}
			className={classes['reject-reason']}
		>
			<div className={`${classes['reject-reason__container']} ${classes['reject-reason__container_textarea']}`}>
				<span className={classes['reject-reason__text']}>Причина отклонения</span>
				<textarea
					onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
						setReason(event.target.value);
					}}
					className={classes['reject-reason__input']}
					name="reason"
					required
				/>
			</div>
			<div className={classes['reject-reason__container']}>
				<button type="submit" className={classes['reject-reason__button']}>
					Отправить
				</button>
				<button
					type="button"
					onClick={() => {
						setIsReject(false);
					}}
					className={classes['reject-reason__button']}
				>
					Отменить
				</button>
			</div>
		</form>
	);
};

export default CoordinationForm;
