import React from 'react';
import useFetch from 'use-http';
import { useNavigate } from 'react-router-dom';
import Table from 'components/UI/Table';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';

const groupBymanager = (data: any) => {
	return data.reduce((acc: any, item: any) => {
		if (!acc[item.manager]) {
			acc[item.manager] = {
				manager: item.manager,
				[item.statuses]: item.count,
				emailManager: item.emailManager,
				countNotBanners: item.countNotBanners,
			};
		} else {
			acc[item.manager] = { ...acc[item.manager], [item.statuses]: item.count, emailManager: item.emailManager };
		}
		return acc;
	}, {});
};

const years = [
	{ value: 2022, label: '2022' },
	{ value: 2023, label: '2023' },
	{ value: 2024, label: '2024' },
	{ value: 2025, label: '2025' },
	{ value: 2026, label: '2026' },
	{ value: 2027, label: '2027' },
	{ value: 2028, label: '2028' },
	{ value: 2029, label: '2029' },
	{ value: 2030, label: '2030' },
];

const months = [
	{ value: 1, label: 'Январь' },
	{ value: 2, label: 'Февраль' },
	{ value: 3, label: 'Март' },
	{ value: 4, label: 'Апрель' },
	{ value: 5, label: 'Май' },
	{ value: 6, label: 'Июнь' },
	{ value: 7, label: 'Июль' },
	{ value: 8, label: 'Август' },
	{ value: 9, label: 'Сентябрь' },
	{ value: 10, label: 'Октябрь' },
	{ value: 11, label: 'Ноябрь' },
	{ value: 12, label: 'Декабрь' },
];

const date = new Date();

export default function StoreStatuses() {
	const { get, loading, error, response } = useFetch();
	const [data, setData] = React.useState([]);

	const [year, setYear] = React.useState(date.getFullYear());
	const [month, setMonth] = React.useState(date.getMonth() + 1);

	const load = async () => {
		const result = await get(`/api/stores/statuses?year=${year}&month=${month}`);
		const byManager = groupBymanager(result);
		setData(Object.values(byManager));
	};

	React.useEffect(() => {
		load();
	}, [year, month]);

	const navigate = useNavigate();

	const headers = [
		{
			value: 'manager',
			title: 'Региональный директор',
			formatted: React.useCallback((row: any) => {
				const handleClick = () => {
					navigate(`/admin/stores/${row.emailManager}/statuses`);
				};
				return (
					<Button size="sm" variant="secondary" onClick={handleClick}>
						{row.manager}
					</Button>
				);
			}, []),
		},
		{ value: 'accept', title: 'Выполненный фотоотчет' },
		{ value: 'reject', title: 'Отклоненный фотоотчет' },
		{ value: 'countNotBanners', title: 'Не предоставлен фотоотчет' },
	];

	const handleChangeYear = (param: number) => {
		setYear(param);
	};

	const handleChangeMonth = (param: number) => {
		setMonth(param);
	};

	return (
		<div>
			{data && (
				<div>
					{error && 'Error!'}
					{loading && 'Загрузка...'}
					<div className="row">
						<div className="column-md-3">
							<Select
								onChange={handleChangeYear}
								options={years.filter(y => y.value <= year)}
								label="Год"
								defaultValue={year}
							/>
						</div>
						<div className="column-md-3">
							<Select onChange={handleChangeMonth} options={months} label="Месяц" defaultValue={month} />
						</div>
					</div>
					<Table headers={headers} rows={data} />
				</div>
			)}
		</div>
	);
}
