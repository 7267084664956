import React from 'react';
import useFetch from 'use-http';

import Table from 'components/UI/Table';
import ExampleForm from 'components/ExampleForm';
import ZoomComponent from 'components/UI/Zoom';

const host = process.env.REACT_APP_BACKEND_URL;

export default function Example() {
	const { get, loading, error, response } = useFetch();
	const [data, setData] = React.useState([]);

	const load = async () => {
		const result = await get(`/api/example`);
		setData(result);
	};

	React.useEffect(() => {
		load();
	}, []);

	const headers = [
		{ value: 'title', title: 'Название контрагента' },
		{
			value: 'preview',
			title: 'Фото',
			formatted: React.useCallback((row: any) => row['preview.uri'] && <ZoomComponent src={row['preview.uri']} />, []),
		},
	];

	return (
		<div>
			{data && (
				<div>
					{error && 'Error!'}
					{loading && 'Загрузка...'}
					<ExampleForm init={load} />

					<Table headers={headers} rows={data} />
				</div>
			)}
		</div>
	);
}
