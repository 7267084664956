import React, { ChangeEvent, FormEvent, useCallback } from 'react';
import useFetch from 'use-http';
import { useParams } from 'react-router-dom';

import Table from 'components/UI/Table';
import PlaceForm from 'components/PlaceForm';
import moment, { Moment } from 'moment';
import ZoomComponent from 'components/UI/Zoom';
import CheckIcon from 'assets/img/svg/checked.svg';
import PenToSquareIcon from 'assets/img/svg/pen-to-square-solid.svg';
import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import classes from 'components/AdminLayout/styles.module.scss';
import * as yup from 'yup';
import Select from 'components/UI/Select';
import { IPlacesResponse, PlacesStatuses, TypeAllocationEnum, getTypeAllocation } from 'types/places';
// import { useLayoutState } from '../../../LayoutContext';
import { GroupsEnum, RolesEnum } from 'types/users';
import { $user } from 'models/user';
import { useUnit } from 'effector-react';
import type { IStore } from 'dto/store.dto';
import acceptedIcon from 'assets/img/svg/accepted.svg';
import rejectedIcon from 'assets/img/svg/rejected.svg';
import pendingIcon from 'assets/img/svg/pending.svg';
import CoordinationForm from 'components/CoordinationForm';
import CloseCounterparty from 'components/CloseCounterparty';
import Changes from 'components/Changes';
import styles from './index.module.scss';

const startDate = moment().subtract(3, 'years');

const iconStatus = {
	accepted: acceptedIcon,
	pending: pendingIcon,
	rejected: rejectedIcon,
};

export const CreateSchema = yup.object({
	exampleId: yup.string().required('Поле обязательно к заполнению'),
	counterpartyId: yup.string().required('Поле обязательно к заполнению'),
	file: yup.mixed().required('Поле обязательно к заполнению'),
	doc: yup.mixed(),
	beginAt: yup
		.date()
		.min(startDate, `Дата начала не должна быть меньше ${startDate.format('DD.MM.YYYY')}`)
		.required('Поле обязательно к заполнению'),
	endAt: yup
		.date()
		.when('beginAt', (beginAt, schema) => {
			return beginAt && schema.min(beginAt, 'Дата окончания не должна быть меньше даты начала');
		})
		.required('Поле обязательно к заполнению'),
	typeAllocation: yup.string().required('Поле обязательно к заполнению'),
	costAllocation: yup.mixed(),
});

const placesOptions = [
	{ value: 'all', label: 'Все' },
	{ value: 'open', label: 'Актуальные' },
	{ value: 'close', label: 'Закрытые' },
];

const filterPlaces = (type: string, item: IPlacesResponse) => {
	const curDate = moment();
	if (type === 'close') {
		return moment(item.endAt) < curDate;
	}
	if (type === 'open') {
		return moment(item.endAt) > curDate;
	}
	return true;
};

export default function Places() {
	const { storeId } = useParams();
	// const { user } = useLayoutState();
	const user = useUnit($user);
	const { get, post, loading, error, response } = useFetch();
	const [data, setData] = React.useState<IPlacesResponse[]>([]);
	const [created, setCreated] = React.useState(false);
	const [type, setType] = React.useState('open');
	const [store, setStore] = React.useState<IStore>();

	const navigate = useNavigate();

	const changeTypeValue = (value: string) => {
		setType(value);
	};

	const load = async (getter?: () => Promise<any>) => {
		let params = '';

		if (storeId) {
			params = `?storeId=${storeId}`;
		}

		if (getter) {
			setType('open');
		}

		const store: IStore = await get(`/api/stores/${storeId}`);
		const place: IPlacesResponse[] = await (getter || get)(`/api/places${params}`);

		setStore(store);
		setData(place);
	};

	React.useEffect(() => {
		load();
	}, [storeId]);
	let index = 1;
	const headers = [
		{ value: 'order.number', title: '№', formatted: () => index++, width: 'min-content' },
		{ value: 'counterparty.title', title: 'Контрагент', formatted: (row: any) => row.counterparty?.title },
		{
			value: 'example.title',
			title: (_: any, data: IPlacesResponse[]) => {
				return (
					<div>
						Место
						<br />
						размещения {data.length}
					</div>
				);
			},
			formatted: (row: any) => row.example?.title,
		},
		{
			value: 'costAllocation',
			title: (_: any, data: IPlacesResponse[]) => {
				const total = data.reduce((acc: number, item: IPlacesResponse) => {
					acc += item.costAllocation ? +item.costAllocation : 0;
					return acc;
				}, 0);
				return (
					<span>
						Стоимость <br /> (Итого: {total})
					</span>
				);
			},
			formatted: (row: any) => {
				const cost = row.costAllocation ? +row.costAllocation : 0;
				return row.typeAllocation === TypeAllocationEnum.paid ? cost : getTypeAllocation(TypeAllocationEnum.free);
			},
		},
		{
			value: 'description',
			title: () => {
				return (
					<div>
						Краткое
						<br />
						описание
					</div>
				);
			},
			formatted: (row: any) => (
				<div title={row['description']} className="ellipsis" style={{ maxWidth: '150px', width: '100%' }}>
					{row?.description}
				</div>
			),
		},
		{
			value: 'beginAt',
			width: '100px',
			title: () => {
				return (
					<div>
						Дата
						<br />
						начала
					</div>
				);
			},
			formatted: React.useCallback((row: any) => row['beginAt'] && moment(row?.beginAt).format('DD.MM.YYYY'), []),
		},
		{
			value: 'endAt',
			width: '100px',
			title: () => {
				return (
					<div>
						Дата
						<br />
						завершения
					</div>
				);
			},
			formatted: React.useCallback((row: any) => row['endAt'] && moment(row?.endAt).format('DD.MM.YYYY'), []),
		},
		{
			value: 'changes',
			title: 'Изменения',
			hidden: !user?.groups?.includes(GroupsEnum.SIGNER),
			formatted: React.useCallback((row: any) => {
				return <Changes row={row} />;
			}, []),
		},
		{
			title: 'Документы',
			value: 'docs',
			width: '100px',
			hidden: !user?.groups?.includes(GroupsEnum.SIGNER) && !user?.groups?.includes(GroupsEnum.ADMIN),
			formatted: React.useCallback((row: any) => {
				if (!Array.isArray(row.docs) || !row.docs.length) {
					return null;
				}

				return (
					<div className={styles.docs}>
						{row.docs.map((doc: any, index: number) => {
							if (!doc || !doc.file) {
								return null;
							}

							return (
								<a key={`docs-${doc.id}-${index}`} className={styles.docs__item} href={doc.file.uri} download>
									{doc.file.originalName.replace(/\.[a-zA-Z0-9]+$/, '')}
								</a>
							);
						})}
					</div>
				);
			}, []),
		},
		{
			title: 'Пример фото',
			value: 'preview',
			formatted: React.useCallback((row: any) => {
				return (
					row.preview && (
						<div className={styles.zoom__image}>
							<ZoomComponent src={row.preview.uri} />
						</div>
					)
				);
			}, []),
		},
		{
			value: 'status',
			title: 'Статус',
			hidden: !user?.groups?.includes(GroupsEnum.SIGNER) && !user?.groups?.includes(GroupsEnum.ADMIN),
			formatted: React.useCallback((row: any) => {
				if (!row['status']) {
					return null;
				}

				const currentStatus: keyof typeof iconStatus = row['status'];

				return (
					<img
						src={iconStatus[currentStatus]}
						alt=""
						title={currentStatus === PlacesStatuses.REJECTED ? row['comment'] : ''}
					></img>
				);
			}, []),
		},
		{
			value: 'operation',
			title: 'Операция',
			hidden: !user?.groups?.includes(GroupsEnum.SIGNER) && !user?.groups?.includes(GroupsEnum.ADMIN),
			formatted: React.useCallback((row: any) => {
				const routeChange = () => {
					const path = `/admin/places/${row.id}`;
					navigate(path);
				};

				return (
					<>
						{user?.groups?.includes(GroupsEnum.SIGNER) && row['status'] && <CoordinationForm row={row} load={load} />}
						{user?.groups?.includes(GroupsEnum.ADMIN) && (
							<Button
								style={{ padding: 0 }}
								size="sm"
								variant={null}
								onClick={routeChange}
								endIcon={<img src={PenToSquareIcon} />}
							></Button>
						)}
					</>
				);
			}, []),
		},
	];

	const onSubmit = React.useCallback(
		async (data: any) => {
			const formData = new FormData();
			formData.append('file', data.file[0]);
			formData.append('exampleId', data.exampleId);
			formData.append('counterpartyId', data.counterpartyId);
			formData.append('beginAt', moment(data.beginAt).format('YYYY-MM-DD'));
			formData.append('typeAllocation', data.typeAllocation);

			if (data.costAllocation) {
				formData.append('costAllocation', data.costAllocation);
			}
			if (data?.doc && data?.doc?.length > 0) {
				for (const docItem of data.doc) {
					formData.append('doc', docItem);
				}
			}
			formData.append('endAt', moment(data.endAt).format('YYYY-MM-DD'));
			if (storeId) {
				formData.append('storeId', storeId);
			}
			if (data?.included) {
				formData.append('included', data.included);
			}
			if (data?.description) {
				formData.append('description', data?.description);
			}

			await post('/api/places', formData);
			await load();
			setCreated(true);
		},
		[storeId],
	);

	return (
		<div>
			{response.ok && data && (
				<div>
					{error && 'Error!'}
					{loading && 'Загрузка...'}
					{created && <div className={classes.warn}>Обновление информации в паспортах может занять 5-10 минут</div>}
					{storeId && (
						<p className={styles.title}>
							{store?.number || '0'}. {store?.title}
						</p>
					)}
					<CloseCounterparty storeId={storeId!} load={load} />
					{storeId && user?.groups?.includes(GroupsEnum.ADMIN) && (
						<PlaceForm onSubmit={onSubmit} schema={CreateSchema} />
					)}

					<div className="row">
						<h1>Фильтр</h1>
					</div>
					<div className="row">
						<div className={`${styles.select} column-md-3`}>
							<Select value={type} onChange={changeTypeValue} options={placesOptions} label="Места размещения" />
						</div>
					</div>
					<Table headers={headers} rows={data.filter((item: IPlacesResponse) => filterPlaces(type, item))} />
				</div>
			)}
		</div>
	);
}
