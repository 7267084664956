// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controller--K0ely{max-width:initial}.date--m_nIl{min-width:135px}", "",{"version":3,"sources":["webpack://./src/pages/admin/Report/index.module.scss"],"names":[],"mappings":"AAAA,mBACC,iBAAA,CAGD,aACC,eAAA","sourcesContent":[".controller {\n\tmax-width: initial;\n}\n\n.date {\n\tmin-width: 135px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controller": "controller--K0ely",
	"date": "date--m_nIl"
};
export default ___CSS_LOADER_EXPORT___;
