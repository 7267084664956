import Layout from 'components/Layout';
import styles from './styles.module.scss';

export default function NotFound() {
	return (
		<Layout>
			<div className={styles['not-found']}>Страница не найдена.</div>
		</Layout>
	);
}
