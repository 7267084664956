import React from 'react';
import useFetch from 'use-http';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import File from 'components/UI/File';
import Select from 'components/UI/Select';
import { downloadFile } from 'utils/helpers';
import styles from './index.module.scss';

export default function ExampleForm({ init }: { init: () => Promise<void> }) {
	const { handleSubmit, formState, control } = useForm<any>();
	const { errors } = formState;
	const { post, get, loading, error, response } = useFetch();

	const onSubmit: SubmitHandler<any> = async data => {
		const result = await downloadFile(post, data?.file);
		await post('/api/example', { title: data.title, previewId: result.id });
		await init();
	};

	return (
		<div>
			{error && 'Error!'}
			{loading && 'Загрузка...'}
			<form onSubmit={handleSubmit(onSubmit)}>
				<table>
					<tbody>
						<tr className={styles.wrapper}>
							<td className={`column-md-12 ${styles.controller}`}>
								<Controller
									name="title"
									control={control}
									rules={{ required: 'Обязательное поле' }}
									render={({ field, fieldState }) => (
										<Input
											onChange={field.onChange}
											onBlur={field.onBlur}
											name={field.name}
											error={fieldState.error}
											label="Название"
										/>
									)}
								/>
							</td>
							<td className={`column-md-4 ${styles.controller}`}>
								<Controller
									name="file"
									control={control}
									render={({ field, fieldState }) => (
										<File
											onChange={field.onChange}
											name={field.name}
											labelClass="label__title"
											error={fieldState.error}
											nowrap
											label="Выберите файл"
										/>
									)}
								/>
							</td>
							<td className={`column-md-4 ${styles.controller}`}>
								<div style={{ marginTop: '37px' }}>
									<Button style={{ background: 'rgb(83, 195, 92)' }} type="submit" size="md">
										Добавить
									</Button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
	);
}
