// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".coordination--BkffM{display:flex;flex-direction:column;gap:5px;margin-bottom:10px}.coordination__button--QfXtQ{display:block;color:#fff;cursor:pointer;width:100%}.coordination__button_accept--a04b9{background-color:green}.coordination__button_reject--VgGfP{background-color:red}.coordination__button_disabled--NA81s{display:none}.reject-reason--dDd8p{display:flex;gap:10px}.reject-reason__container--xpuB9{display:flex;flex-direction:column;gap:5px}.reject-reason__container_textarea--akj2v{width:150px}.reject-reason__input--H9i2T{display:block;resize:vertical}.reject-reason__button--d9OAk{display:block;width:max-content;color:#fff;background-color:#448452;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/CoordinationForm/index.module.scss"],"names":[],"mappings":"AAAA,qBACC,YAAA,CACA,qBAAA,CACA,OAAA,CACA,kBAAA,CAEA,6BACC,aAAA,CACA,UAAA,CACA,cAAA,CACA,UAAA,CAEA,oCACC,sBAAA,CAGD,oCACC,oBAAA,CAGD,sCACC,YAAA,CAKH,sBACC,YAAA,CACA,QAAA,CAEA,iCACC,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,0CACC,WAAA,CAOF,6BACC,aAAA,CACA,eAAA,CAGD,8BACC,aAAA,CACA,iBAAA,CACA,UAAA,CACA,wBAAA,CACA,cAAA","sourcesContent":[".coordination {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 5px;\n\tmargin-bottom: 10px;\n\n\t&__button {\n\t\tdisplay: block;\n\t\tcolor: white;\n\t\tcursor: pointer;\n\t\twidth: 100%;\n\n\t\t&_accept {\n\t\t\tbackground-color: green;\n\t\t}\n\n\t\t&_reject {\n\t\t\tbackground-color: red;\n\t\t}\n\n\t\t&_disabled {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n}\n\n.reject-reason {\n\tdisplay: flex;\n\tgap: 10px;\n\n\t&__container {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tgap: 5px;\n\n\t\t&_textarea {\n\t\t\twidth: 150px;\n\t\t}\n\t}\n\n\t&__text {\n\t}\n\n\t&__input {\n\t\tdisplay: block;\n\t\tresize: vertical;\n\t}\n\n\t&__button {\n\t\tdisplay: block;\n\t\twidth: max-content;\n\t\tcolor: white;\n\t\tbackground-color: #448452;\n\t\tcursor: pointer;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coordination": "coordination--BkffM",
	"coordination__button": "coordination__button--QfXtQ",
	"coordination__button_accept": "coordination__button_accept--a04b9",
	"coordination__button_reject": "coordination__button_reject--VgGfP",
	"coordination__button_disabled": "coordination__button_disabled--NA81s",
	"reject-reason": "reject-reason--dDd8p",
	"reject-reason__container": "reject-reason__container--xpuB9",
	"reject-reason__container_textarea": "reject-reason__container_textarea--akj2v",
	"reject-reason__input": "reject-reason__input--H9i2T",
	"reject-reason__button": "reject-reason__button--d9OAk"
};
export default ___CSS_LOADER_EXPORT___;
