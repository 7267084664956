// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".operation--_el_T{justify-content:center}.operation__item--JIGtR{max-width:initial}@media screen and (max-width: 1400px){.operation__item--JIGtR{display:flex;justify-content:center;flex-grow:1}.operation__item--JIGtR>button{min-width:80px;height:32px;width:100%;padding:5px}.operation__item--JIGtR>button>span{white-space:wrap !important}}.label--TOQZW{white-space:nowrap}.input__info--qAOPP{max-width:250px !important}@media screen and (max-width: 800px){.wrapper--dLmmL{flex-direction:column}}", "",{"version":3,"sources":["webpack://./src/pages/admin/Stores/index.module.scss"],"names":[],"mappings":"AAAA,kBACC,sBAAA,CAEA,wBACC,iBAAA,CAEA,sCAHD,wBAIE,YAAA,CACA,sBAAA,CACA,WAAA,CAEA,+BACC,cAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CAEA,oCACC,2BAAA,CAAA,CAOL,cACC,kBAAA,CAGD,oBACC,0BAAA,CAGD,qCACC,gBACC,qBAAA,CAAA","sourcesContent":[".operation {\n\tjustify-content: center;\n\n\t&__item {\n\t\tmax-width: initial;\n\n\t\t@media screen and (max-width: 1400px) {\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: center;\n\t\t\tflex-grow: 1;\n\n\t\t\t> button {\n\t\t\t\tmin-width: 80px;\n\t\t\t\theight: 32px;\n\t\t\t\twidth: 100%;\n\t\t\t\tpadding: 5px;\n\n\t\t\t\t> span {\n\t\t\t\t\twhite-space: wrap !important;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n\n.label {\n\twhite-space: nowrap;\n}\n\n.input__info {\n\tmax-width: 250px !important;\n}\n\n@media screen and (max-width: 800px) {\n\t.wrapper {\n\t\tflex-direction: column;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"operation": "operation--_el_T",
	"operation__item": "operation__item--JIGtR",
	"label": "label--TOQZW",
	"input__info": "input__info--qAOPP",
	"wrapper": "wrapper--dLmmL"
};
export default ___CSS_LOADER_EXPORT___;
