// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--ba92g{position:absolute;display:flex;align-items:center;justify-content:center;top:0;left:0;min-width:100%;min-height:100%;z-index:2}.loader--t4VtH{display:block;width:50px;height:50px;border-radius:50%;border:3px solid #439999;border-top:3px solid rgba(0,0,0,0);animation-name:spin-loader--zv95M;animation-duration:1000ms;animation-timing-function:linear;animation-iteration-count:infinite}@keyframes spin-loader--zv95M{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/components/TableLoader/index.module.scss"],"names":[],"mappings":"AAAA,kBACC,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,KAAA,CACA,MAAA,CACA,cAAA,CACA,eAAA,CACA,SAAA,CAGD,eACC,aAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,wBAAA,CACA,kCAAA,CACA,iCAAA,CACA,yBAAA,CACA,gCAAA,CACA,kCAAA,CAGD,8BACC,KACC,sBAAA,CAGD,GACC,wBAAA,CAAA","sourcesContent":[".container {\n\tposition: absolute;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\ttop: 0;\n\tleft: 0;\n\tmin-width: 100%;\n\tmin-height: 100%;\n\tz-index: 2;\n}\n\n.loader {\n\tdisplay: block;\n\twidth: 50px;\n\theight: 50px;\n\tborder-radius: 50%;\n\tborder: 3px solid #439999;\n\tborder-top: 3px solid transparent;\n\tanimation-name: spin-loader;\n\tanimation-duration: 1000ms;\n\tanimation-timing-function: linear;\n\tanimation-iteration-count: infinite;\n}\n\n@keyframes spin-loader {\n\tfrom {\n\t\ttransform: rotate(0deg);\n\t}\n\n\tto {\n\t\ttransform: rotate(360deg);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--ba92g",
	"loader": "loader--t4VtH",
	"spin-loader": "spin-loader--zv95M"
};
export default ___CSS_LOADER_EXPORT___;
