import useFetch from 'use-http';
import { useForm, SubmitHandler } from 'react-hook-form';
import classes from 'components/Layout/styles.module.scss';
import useLocalStorage from 'use-local-storage';
import { IStore } from 'dto/store.dto';

export default function SupportForm() {
	const { handleSubmit, formState, control, register, watch, setValue } = useForm<any>();
	const { errors } = formState;
	const { post, loading, error, response } = useFetch();
	const [store] = useLocalStorage<IStore | undefined>(`store`, undefined);

	const onSubmit: SubmitHandler<any> = async data => {
		const formData = new FormData();
		formData.append('description', data.description);
		formData.append('storeNumber', store ? store.number : '');
		formData.append('storeTitle', store ? store.title : '');
		for (let i = 0; i < data.files.length; i += 1) {
			formData.append('files', data.files[i]);
		}

		await post('/api/tickets', formData);
		setValue('description', '');
		setValue('files', '');
	};

	return (
		<div>
			<div style={{ textAlign: 'center' }}>
				{error && 'Error!'}
				{loading && 'Загрузка...'}
				{response.ok && watch('description')?.length === 0 && 'Письмо отправлено'}
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<header className={classes.header}>
					<h1 className={classes['time-header']}>Форма обратной связи</h1>
				</header>
				<div className={classes.main}>
					<div className={classes.help}>
						<div className={classes['help__form']}>
							<textarea
								{...register('description')}
								className={classes['textarea']}
								placeholder="опишите свою проблему..."
							></textarea>
							<label className={classes.file}>
								<input type="file" {...register('files')} />
								<span className={classes['file__txt']}>
									{watch('files') && watch('files').length === 1 ? watch('files')[0].name : 'Прикрепите файл'}
								</span>
							</label>
						</div>
						<div className={classes['help__row']}>
							<button
								disabled={loading || watch('description')?.length === 0}
								type="submit"
								className={`${classes.btn} ${classes['btn--primery']} ${classes['btn-long']}`}
							>
								Отправить
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}
