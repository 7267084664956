import { KCTokenParsed, JWTTokenParsed } from 'dto/user.dto';
import { createEffect, createStore, createEvent } from 'effector';

export const $token = createStore<string>('');

export const $coordinate = createStore<{ latitude?: number; longitude?: number } | undefined>({});

export const setToken = createEvent<string>();

export const delToken = createEvent();

export const setCoordinate = createEvent<{ latitude: number; longitude: number } | undefined>();

export const $user = createStore<Partial<(JWTTokenParsed & KCTokenParsed) | null>>(null);

export const setUser = createEvent<Partial<JWTTokenParsed & KCTokenParsed>>();

$user.on(setUser, (state, payload) => {
	return { ...state, ...payload };
});

$token
	.on(setToken, (_, payload) => {
		return payload;
	})
	.on(delToken, () => {
		return '';
	});

$coordinate.on(setCoordinate, (state, payload) => {
	return payload;
});
