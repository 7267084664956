import React from 'react';
import useFetch from 'use-http';
import PlaceForm from 'components/PlaceForm';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import classes from 'components/AdminLayout/styles.module.scss';
import * as yup from 'yup';

const startDate = moment().subtract(3, 'years');

const EditSchema = yup.object({
	exampleId: yup.string().required('Поле обязательно к заполнению'),
	counterpartyId: yup.string().required('Поле обязательно к заполнению'),
	beginAt: yup
		.date()
		.min(startDate, `Дата начала не должна быть меньше ${startDate.format('DD.MM.YYYY')}`)
		.required('Поле обязательно к заполнению'),
	file: yup.mixed(),
	doc: yup.mixed(),
	endAt: yup
		.date()
		.when('beginAt', (beginAt, schema) => {
			return beginAt && schema.min(beginAt, 'Дата окончания не должна быть меньше даты начала');
		})
		.required('Поле обязательно к заполнению'),
	typeAllocation: yup.string().required('Поле обязательно к заполнению'),
	costAllocation: yup.mixed(),
});

export default function Example() {
	const { id } = useParams();
	const { get, patch, loading, error, response } = useFetch();
	const [update, setUpdate] = React.useState(false);
	const [place, setPlace] = React.useState({
		'store.id': '0',
		id: null,
	});

	const load = async () => {
		const result = await get(`/api/places/${id}`);
		setPlace(result);
	};

	React.useEffect(() => {
		load();
	}, []);

	const onSubmit = React.useCallback(
		async (data: any) => {
			const formData = new FormData();

			formData.append('exampleId', data.exampleId);
			formData.append('counterpartyId', data.counterpartyId);
			formData.append('beginAt', moment(data.beginAt).format('YYYY-MM-DD'));
			formData.append('typeAllocation', data.typeAllocation);

			if (data.costAllocation) {
				formData.append('costAllocation', data.costAllocation);
			}
			formData.append('endAt', moment(data.endAt).format('YYYY-MM-DD'));
			if (data?.file && data?.file?.length > 0) {
				for (const fileItem of data.file) {
					formData.append('file', fileItem);
				}
			}
			if (data?.doc && data?.doc?.length > 0) {
				for (const fileItem of data.doc) {
					formData.append('doc', fileItem);
				}
			}
			if (place['store.id']) {
				formData.append('storeId', place['store.id']);
			}
			if (data?.included) {
				formData.append('included', data.included);
			}
			if (data?.description) {
				formData.append('description', data?.description);
			}

			await patch(`/api/places/${place.id}`, formData);
			setUpdate(true);
		},
		[place],
	);

	return (
		<div>
			{place && place.id && (
				<div>
					{error && 'Error!'}
					{loading && 'Загрузка...'}
					{update && <div className={classes.warn}>Обновление информации в паспортах может занять 5-10 минут</div>}
					{update && 'Успешно обновлено'}

					<PlaceForm onSubmit={onSubmit} defaultValues={place} mode="edit" schema={EditSchema} />
				</div>
			)}
		</div>
	);
}
