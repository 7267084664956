import React from 'react';
import useFetch from 'use-http';
import { useForm, Controller } from 'react-hook-form';
import File from 'components/UI/File';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';
import Textarea from 'components/UI/Textarea';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { SchemaOf } from 'yup';
import { TypeAllocationEnum, getTypeAllocation } from 'types/places';
import styles from './index.module.scss';

interface PlaceFormSchema {
	exampleId: string;
	counterpartyId: string;
	beginAt: Date;
	endAt: Date;
	file?: File;
	doc?: File;
	typeAllocation: string;
	costAllocation: number;
}

const typeOptions = [
	{ value: TypeAllocationEnum.paid, label: getTypeAllocation(TypeAllocationEnum.paid) },
	{ value: TypeAllocationEnum.free, label: getTypeAllocation(TypeAllocationEnum.free) },
];

export default function PlaceForm({
	onSubmit,
	schema,
	defaultValues = {},
	mode = 'create',
}: {
	onSubmit: (data: any) => void;
	schema: SchemaOf<PlaceFormSchema>;
	defaultValues?: any;
	mode?: string;
}) {
	const { handleSubmit, formState, control } = useForm<any>({ defaultValues, resolver: yupResolver(schema) });
	const { errors } = formState;
	const { get, loading, error, response } = useFetch();

	const [options, setOptions] = React.useState([]);
	const [exmpOtions, setExmpOtions] = React.useState([]);

	const load = async () => {
		const exmpResult = await get('/api/example');
		if (exmpResult && exmpResult.length > 0) {
			setExmpOtions(
				exmpResult.map((item: any) => {
					return { value: item.id, label: item.title };
				}),
			);
		}

		const result = await get('/api/counterparty');
		if (result && result.length > 0) {
			setOptions(
				result.map((item: any) => {
					return { value: item.id, label: item.title };
				}),
			);
		}
	};

	React.useEffect(() => {
		load();
	}, []);

	return (
		<div>
			{error && 'Error!'}
			{loading && 'Загрузка...'}
			<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
				<div className="row">
					<div className="column-xs-6">
						<div className="row">
							<div className="column-xs-6">
								<Controller
									name="typeAllocation"
									control={control}
									rules={{ required: 'Обязательное поле' }}
									render={({ field, fieldState }) => (
										<Select
											onChange={field.onChange}
											options={typeOptions}
											error={fieldState.error}
											label="Тип размещения"
											defaultValue={field.value}
										/>
									)}
								/>
							</div>
							<div className="column-xs-6">
								<Controller
									name="costAllocation"
									control={control}
									rules={{ required: 'Обязательное поле' }}
									render={({ field, fieldState }) => (
										<Input
											onChange={field.onChange}
											onBlur={field.onBlur}
											name={field.name}
											error={fieldState.error}
											label="Стоимость размещения"
											defaultValue={field.value}
											type="number"
											min="0"
										/>
									)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="column-xs-6">
								<Controller
									name="exampleId"
									control={control}
									rules={{ required: 'Обязательное поле' }}
									render={({ field, fieldState }) => (
										<Select
											onChange={field.onChange}
											options={exmpOtions}
											error={fieldState.error}
											label="Место размещения"
											defaultValue={field.value}
										/>
									)}
								/>
							</div>
							<div className="column-xs-6">
								<Controller
									name="counterpartyId"
									control={control}
									rules={{ required: 'Обязательное поле' }}
									render={({ field, fieldState }) => (
										<Select
											onChange={field.onChange}
											options={options}
											error={fieldState.error}
											label="Контрагент"
											defaultValue={field.value}
											isDisabled={mode === 'edit'}
										/>
									)}
								/>
							</div>
						</div>
						<div className="row">
							<div className={`column-xs-3 ${styles.controller}`}>
								<Controller
									name="beginAt"
									control={control}
									render={({ field, fieldState }) => (
										<Input
											onChange={field.onChange}
											onBlur={field.onBlur}
											name={field.name}
											error={fieldState.error}
											label="Дата начала"
											type="date"
											defaultValue={field.value && moment(field.value).format('YYYY-MM-DD')}
										/>
									)}
								/>
							</div>
							<div className={`column-xs-3 ${styles.controller}`}>
								<Controller
									name="endAt"
									control={control}
									render={({ field, fieldState }) => {
										return (
											<Input
												onChange={field.onChange}
												onBlur={field.onBlur}
												name={field.name}
												error={fieldState.error}
												label="Дата окончания"
												type="date"
												defaultValue={field.value && moment(field.value).format('YYYY-MM-DD')}
											/>
										);
									}}
								/>
							</div>
						</div>
						<div className="row">
							<div className={`column-xs-3 ${styles.controller}`}>
								<Controller
									name="file"
									control={control}
									rules={{ required: 'Обязательное поле' }}
									render={({ field, fieldState }) => (
										<File
											onChange={field.onChange}
											name={field.name}
											labelClass="label__title"
											error={fieldState.error}
											label="Пример фото"
											nowrap
											id="file"
											multiple={true}
										/>
									)}
								/>
							</div>
							<div className={`column-xs-3 ${styles.controller}`}>
								<Controller
									name="doc"
									control={control}
									render={({ field, fieldState }) => (
										<File
											onChange={field.onChange}
											name={field.name}
											labelClass="label__title"
											error={fieldState.error}
											label="Паспорт/договор размещения"
											noWrapLabel
											nowrap
											id="doc"
											multiple={true}
										/>
									)}
								/>
							</div>
						</div>
					</div>
					<div className="column-xs-6">
						<div className="row">
							<div className="column-xs-8">
								<Controller
									name="description"
									control={control}
									render={({ field, fieldState }) => (
										<Textarea
											onChange={field.onChange}
											onBlur={field.onBlur}
											name={field.name}
											error={fieldState.error}
											label="Краткое описание"
											defaultValue={field.value}
										/>
									)}
								/>
							</div>
							<div className="column-xs-4">
								<div style={{ marginTop: '36px' }}>
									<Button style={{ background: 'rgb(83, 195, 92)' }} type="submit" size="md">
										{mode === 'edit' ? 'Обновить' : 'Добавить'}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}
