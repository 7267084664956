// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width: 767px){.controller--YQo2i>*{margin:0 !important}}.wrapper--dRdTN{display:flex;flex-wrap:wrap}", "",{"version":3,"sources":["webpack://./src/components/ExampleForm/index.module.scss"],"names":[],"mappings":"AACC,qCACC,qBACC,mBAAA,CAAA,CAKH,gBACC,YAAA,CACA,cAAA","sourcesContent":[".controller {\n\t@media screen and (max-width: 767px) {\n\t\t> * {\n\t\t\tmargin: 0 !important;\n\t\t}\n\t}\n}\n\n.wrapper {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controller": "controller--YQo2i",
	"wrapper": "wrapper--dRdTN"
};
export default ___CSS_LOADER_EXPORT___;
