import React from 'react';
import { useNavigate } from 'react-router-dom';
import { $storeList } from 'models/store';
import { useUnit } from 'effector-react';
import { IStore } from 'dto/store.dto';
import classes from 'components/Layout/styles.module.scss';

export default function Section() {
	const navigate = useNavigate();

	const storeList = useUnit($storeList);

	const selectStore = React.useCallback((storeId: string) => {
		navigate(`confirm/${storeId}`);
	}, []);

	const handleBack = React.useCallback(() => {
		navigate('/');
	}, []);

	return (
		<div>
			<div className={classes.main}>
				<div className={classes.address}>
					<h2>Выберите секцию</h2>
				</div>
			</div>
			<div>
				<footer className={classes['footer']}>
					<button onClick={handleBack} className={`${classes.btn} ${classes['btn--secondary']}`}>
						Назад
					</button>
					{storeList.map((item: IStore, index, self) => {
						return (
							index === self.findIndex(t => t.id === item.id) && (
								<button
									key={item.id}
									onClick={() => selectStore(item.id)}
									className={`${classes.btn} ${classes['btn--primery']}`}
								>
									{item.number} {item.title}
								</button>
							)
						);
					})}
				</footer>
			</div>
		</div>
	);
}
