import { RolesEnum } from 'types/users';
import classes from './styles.module.scss';
import TableLoader from 'components/TableLoader';

export interface ITableHeader {
	value: string;
	title?: string | ((row: any, data: any) => void);
	width?: string;
	hidden?: boolean;
	formatted?: (row: any) => any;
}

export interface ITableProps<T> {
	headers: ITableHeader[];
	rows: T[];
	isLoading?: boolean;
}

function renderTitle(variable: any, head: any, rows: any) {
	if (typeof variable === 'string') {
		return variable;
	} else if (typeof variable === 'function') {
		const result = variable(head, rows);
		return result;
	}
	return '';
}

const generateUnique = (headers: ITableHeader[], row: any) => {
	return (
		row?.id ||
		headers.reduce((acc: string, item: ITableHeader) => {
			return acc + (row[item.value] ? row[item.value] : '');
		}, '')
	);
};

export default function Table<T>({ headers, rows, isLoading = false }: ITableProps<T>) {
	return (
		<div className={classes['table-overlay']}>
			{isLoading && <TableLoader />}
			<table className={classes.table}>
				<thead>
					<tr>
						{headers.map(
							(header, index) =>
								!header.hidden && (
									<th style={{ width: header.width ? header.width : 'auto' }} key={`th-${header.value}-${index}`}>
										{renderTitle(header?.title, header, rows)}
									</th>
								),
						)}
					</tr>
				</thead>
				{rows.length > 0 ? (
					<tbody>
						{rows.map((row: any, index: number) => (
							<tr key={`tr-${generateUnique(headers, row)}-${index}`}>
								{headers.map(
									header =>
										!header.hidden && (
											<td key={`td-${header.value}-${index}`}>
												{header.formatted ? header.formatted(row) : row[header.value]}
											</td>
										),
								)}
							</tr>
						))}
					</tbody>
				) : (
					<tbody>
						{!isLoading && (
							<tr>
								<td colSpan={headers.length} style={{ textAlign: 'center' }}>
									Записей не найдено.
								</td>
							</tr>
						)}
					</tbody>
				)}
			</table>
		</div>
	);
}
