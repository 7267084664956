import React, { useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import useFetch from 'use-http';

const ZoomComponent = ({
	src,
	alt = '',
	width = 'auto',
	height = '50px',
}: {
	src: string;
	alt?: string;
	width?: string;
	height?: string;
}) => {
	const [image, setImage] = useState(src);
	const { get } = useFetch();

	useEffect(() => {
		const fetchImage = async () => {
			const data = await get(src);
			setImage(data.uri);
		};

		if (src.startsWith('/api/file/')) {
			fetchImage();
		}
	}, []);

	return (
		<Zoom transitionDuration={10} zoomMargin={20}>
			<img alt={alt} src={image} width={width} className="img" style={{ maxHeight: height }} />
		</Zoom>
	);
};

export default ZoomComponent;
