import React, { FormEvent } from 'react';
import { $user } from 'models/user';
import styles from './index.module.scss';
import Input from 'components/UI/Input';
import { useFetch } from 'use-http';
import { useUnit } from 'effector-react';
import { GroupsEnum } from 'types/users';

interface Props {
	storeId: string;
	load: (getter?: () => Promise<any>) => Promise<any>;
}

const CloseCounterparty = ({ storeId, load }: Props) => {
	const { post } = useFetch();
	const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
	const [isClose, setIsClose] = React.useState<boolean>(false);
	const [isCloseAndOpen, setIsCloseAndOpen] = React.useState<boolean>(false);
	const user = useUnit($user);

	function getClosedPlaces(routeOrBody?: string | BodyInit | object, body?: BodyInit | object) {
		return async (): Promise<any> => post(routeOrBody, body);
	}

	function closeCounterPartyHandler(event: FormEvent<HTMLFormElement>): void {
		event.preventDefault();

		const formData = new FormData(event.currentTarget);

		load(
			getClosedPlaces('/api/places/closeCounter', {
				storeId: storeId,
				endAt: formData.get('endAt'),
			}),
		);

		setIsShowModal(false);
		setIsClose(false);
	}

	function closeModal() {
		setIsShowModal(false);
		setIsClose(false);
		setIsCloseAndOpen(false);
	}

	function closeAndOpenCounterPartyHandler(event: FormEvent<HTMLFormElement>): void {
		event.preventDefault();

		const formData = new FormData(event.currentTarget);

		load(
			getClosedPlaces('/api/places/reopenCounter', {
				storeId: storeId,
				newCounterName: formData.get('newCounterName'),
				beginAt: formData.get('beginAt'),
			}),
		);

		setIsShowModal(false);
		setIsCloseAndOpen(false);
	}

	return (
		<>
			{user?.groups?.includes(GroupsEnum.ADMIN) && (
				<button onClick={() => setIsShowModal(true)} className={styles.close}>
					Закрыть контрагента
				</button>
			)}
			<div className={`${styles.overlay} ${isShowModal ? styles.overlay_show : ''}`}>
				<div className={styles.modal}>
					{!isCloseAndOpen && !isClose ? (
						<div className={styles.modal__header}>
							<span className={styles.modal__question}>Закрыть список всех рекламных мест?</span>
							<div className={styles.modal__wrapper}>
								<button
									onClick={() => {
										setIsClose(true);
										setIsCloseAndOpen(false);
									}}
									className={styles.modal__button}
								>
									Да
								</button>
								<button onClick={closeModal} className={styles.modal__button}>
									Нет
								</button>
								<button
									onClick={() => {
										setIsClose(false);
										setIsCloseAndOpen(true);
									}}
									className={styles.modal__button}
								>
									Да, закрыть и открыть новый
								</button>
							</div>
						</div>
					) : null}
					<form onSubmit={closeCounterPartyHandler} className={`${styles.modal__close} ${isClose ? '' : styles.hide}`}>
						<Input name="endAt" type="date" label="Дата завершения размещений" isRequired />
						<div className={styles['modal__button-wrapper']}>
							<button onClick={closeModal} type="button" className={styles.modal__button}>
								Отмена
							</button>
							<button type="submit" className={styles.modal__button}>
								Отправить
							</button>
						</div>
					</form>
					<form
						onSubmit={closeAndOpenCounterPartyHandler}
						className={`${styles.modal__close} ${isCloseAndOpen ? '' : styles.hide}`}
					>
						<Input name="beginAt" type="date" isRequired label="Дата начала размещения" />
						<Input name="newCounterName" type="text" isRequired label="Название контрагента" />
						<div className={styles['modal__button-wrapper']}>
							<button onClick={closeModal} type="button" className={styles.modal__button}>
								Отмена
							</button>
							<button type="submit" className={styles.modal__button}>
								Отправить
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default CloseCounterparty;
