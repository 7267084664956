// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title--NXbmk{margin-bottom:10px}.docs--Rp0AH{display:flex;flex-direction:column;gap:10px;width:150px}.zoom__image--VAbv0{display:flex;justify-content:center;width:100%}.select--h4W3o{max-width:200px !important}.zoom__image--VAbv0{display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/pages/admin/Places/index.module.scss"],"names":[],"mappings":"AAAA,cACC,kBAAA,CAMD,aACC,YAAA,CACA,qBAAA,CACA,QAAA,CACA,WAAA,CAMD,oBACI,YAAA,CACA,sBAAA,CACA,UAAA,CAEJ,eACC,0BAAA,CAGD,oBACC,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".title {\n\tmargin-bottom: 10px;\n}\n\n.changes {\n}\n\n.docs {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 10px;\n\twidth: 150px;\n\n\t&__item {\n\t}\n}\n\n.zoom__image {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n}\n.select {\n\tmax-width: 200px !important;\n}\n\n.zoom__image {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title--NXbmk",
	"docs": "docs--Rp0AH",
	"zoom__image": "zoom__image--VAbv0",
	"select": "select--h4W3o"
};
export default ___CSS_LOADER_EXPORT___;
