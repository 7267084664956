import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $storeList } from 'models/store';
import { useUnit } from 'effector-react';
import { IStore } from 'dto/store.dto';
import classes from 'components/Layout/styles.module.scss';

import useLocalStorage from 'use-local-storage';

export default function ConfirmSection() {
	const navigate = useNavigate();
	const { storeId } = useParams();
	const [store, setStore] = useLocalStorage<IStore | undefined>(`store`, undefined);

	const storeList = useUnit($storeList);

	const handleBack = React.useCallback(() => {
		navigate('/section');
	}, []);

	// React.useEffect(() => {
	//     const storedStore = JSON.parse(localStorage.getItem('store') || '') as IStore | null;
	//     console.log('ConfirmSection', storedStore?.id, store?.id, storedStore?.id !== store?.id)
	//     if (storedStore?.id !== store?.id && storedStore !== null) {
	//       navigate('/')
	//     }
	//   }, [store]);

	const handleConfirm = React.useCallback(() => {
		const found = storeList.find((item: IStore) => item.id === storeId);
		if (found) {
			setStore(found);
		}
		setTimeout(() => {
			navigate('/');
		}, 250);
	}, [storeList, storeId]);

	const selectedStore = storeList.find((item: IStore) => item.id === storeId);

	return (
		<div>
			{selectedStore ? (
				<div>
					<div className={classes.main}>
						<div className={classes.address}>
							<h2>Вы хотите сменить секцию на {selectedStore.title}?</h2>
						</div>
					</div>
					<div>
						<footer className={classes['footer']}>
							<button onClick={handleBack} className={`${classes.btn} ${classes['btn--secondary']}`}>
								Назад
							</button>
							<button onClick={handleConfirm} className={`${classes.btn} ${classes['btn--primery']}`}>
								Подтвердить
							</button>
						</footer>
					</div>
				</div>
			) : (
				<div>Магазин не найден.</div>
			)}
		</div>
	);
}
