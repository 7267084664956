import React from 'react';
import useFetch from 'use-http';
import { useNavigate, useParams } from 'react-router-dom';

import Table from 'components/UI/Table';
import moment from 'moment';
import Button from 'components/UI/Button';
import OperationFrom from 'components/OperationFrom';
import ZoomComponent from 'components/UI/Zoom';
import DoubleZoom from 'components/UI/DoubleZoom';
import { GroupsEnum, RolesEnum } from 'types/users';
// import { useLayoutState } from '../../../LayoutContext';
import { StatusEnum } from 'types/banners';
import { $user } from 'models/user';
import { useUnit } from 'effector-react';
import FileDownload from 'assets/img/svg/file_download_black_24dp.svg';
import { getTypeAllocation, IPlacesResponse, PlacesStatuses, TypeAllocationEnum } from 'types/places';
import RequestUpToDateData from 'components/RequestUpToDateData';
import styles from './index.module.scss';

const host = process.env.REACT_APP_BACKEND_URL;

function toDecimal(coordinate: string) {
	const [degrees, minutes, seconds] = coordinate.split(';');
	const decimal = (Number(minutes) * 60 + Number(seconds)) / (60 * 60);
	return String(Number(degrees) + decimal);
}

export const renderStatus = (
	postfix: string,
	row: any,
	updateBanner: (postfix: string, bannerId: number) => Promise<void>,
	init: () => Promise<void>,
	user: any,
) => {
	const handleCancel = async (postfix: string, bannerId: number): Promise<void> => {
		if (confirm('Вы точно хотите изменить статус согласования?')) {
			await updateBanner(postfix, bannerId);
			await init();
		}
	};

	switch (row[`banner.status${postfix}`]) {
		case StatusEnum.accept: {
			return (
				<div className={`row ${styles.col}`}>
					<div className="column-xs-4">Принят</div>
					<div className="column-xs-8">
						{user && postfix === 'Accnt' && user.groups.includes(GroupsEnum.CANCELER) ? (
							<button type="submit" onClick={() => handleCancel(postfix, row.id)}>
								X
							</button>
						) : (
							''
						)}
					</div>
				</div>
			);
		}
		case StatusEnum.reject: {
			return (
				<div className="row">
					<div className="column-xs-4">Не принят</div>
					<div className="column-xs-8">
						<div className="ellipsis" title={row[`banner.comment${postfix}`]}>
							{row[`banner.comment${postfix}`]}
						</div>
					</div>
				</div>
			);
		}
		default:
			return (
				<div className="row">
					<div className="column-xs-12">Не просмотрен</div>
				</div>
			);
	}
};

export default function PassportDetail() {
	const { passportId } = useParams();
	const user = useUnit($user);
	const { get, loading, error, response, patch } = useFetch();
	const [passport, setPassport] = React.useState<any>({});
	const [data, setData] = React.useState<any>([]);

	const load = React.useCallback(async () => {
		const result = await get(`/api/passport/${passportId}`);
		setPassport(result);

		if (result?.places) {
			const bannersList = await get(`/api/passport/${passportId}/places`);
			setData(bannersList);
		}
	}, []);

	React.useEffect(() => {
		load();
	}, []);

	const updateBanner = React.useCallback(async (postfix: string, bannerId: number) => {
		await patch(`/api/banners/${bannerId}`, {
			[`status${postfix}`]: PlacesStatuses.PENDING,
			[`comment${postfix}`]: '',
		});
	}, []);
	let index = 1;

	const headers = [
		{ value: 'order.number', title: '№', formatted: () => index++, width: 'min-content' },
		{ value: 'example.title', title: 'Место размещения' },
		{
			value: 'preview.uri',
			title: 'Фото пример/факт',
			formatted: (row: any) => {
				return (
					row['preview.uri'] && (
						<DoubleZoom src1={row['preview.uri'] && row['preview.uri']} src2={row['file.uri'] && row['file.uri']} />
					)
				);
			},
		},
		{
			value: 'comment',
			title: 'Фото факт',
			formatted: (row: any) => (
				<span>
					{row['file.uri'] && (
						<div className={`row ${styles.col}`}>
							<div className="column-xs-6">
								<ZoomComponent src={row['file.uri']} />
							</div>
							<div className="column-xs-6">
								<Button
									onClick={() => {
										window.location.href = `/api/file/download?id=${row['file.id']}`;
									}}
									variant="secondary"
									size="sm"
									endIcon={
										<i>
											<img
												alt="icon"
												src={FileDownload}
												style={{
													filter:
														'invert(22%) sepia(37%) saturate(900%) hue-rotate(193deg) brightness(90%) contrast(83%)',
												}}
											/>
										</i>
									}
								>
									Скачать
								</Button>
							</div>
						</div>
					)}
				</span>
			),
		},
		{
			value: 'banner.photoAt',
			title: 'Отчетная дата съемки',
			formatted: React.useCallback(
				(row: any) => row['banner.photoAt'] && moment.utc(row['banner.photoAt']).format('DD.MM.YYYY'),
				[],
			),
		},
		{
			value: 'place.costAllocation',
			title: (_: any, data: any[]) => {
				const total = data.reduce((acc: number, item: any) => {
					return acc + Number(item['place.costAllocation']) || 0;
				}, 0);

				return (
					<span>
						Стоимость <br /> (Итого: {total})
					</span>
				);
			},
			formatted: (row: any) => {
				return Number(row['place.costAllocation']) || getTypeAllocation(TypeAllocationEnum.free);
			},
		},
		{
			value: 'posted',
			title: 'Отчет',
			formatted: (row: any) => {
				return row['banner.posted'] !== null && (row['banner.posted'] ? 'Реклама в наличии' : 'Реклама отсутствует');
			},
		},
		{
			value: 'geo',
			title: 'Геотег',
			formatted: (row: any) => {
				return (
					row['file.uri'] &&
					row['banner.lat'] &&
					row['banner.lon'] && (
						<p style={{ width: '200px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
							<a
								href={`https://geotree.ru/coordinates?lat=${toDecimal(row['banner.lat'])}&lon=${toDecimal(
									row['banner.lon'],
								)}&z=18&mlat=${toDecimal(row['banner.lat'])}&mlon=${toDecimal(row['banner.lon'])}`}
								target="_black"
							>
								Геотег
							</a>
							<br />
							Широта: <span title={row['banner.lat']}>{row['banner.lat']}</span>
							<br />
							Долгота: <span title={row['banner.lon']}>{row['banner.lon']}</span>
						</p>
					)
				);
			},
		},
		{
			value: 'accountant',
			title: 'Бухгалтерия',
			formatted: React.useCallback(
				(row: any) => {
					if (!!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.ACCOUNTANT) > -1) {
						if (row[`banner.statusAccnt`] === StatusEnum.pending) {
							return <OperationFrom row={row} init={load} postfix="Accnt" />;
						}
					}
					return <div>{renderStatus('Accnt', row, updateBanner, load, user)}</div>;
				},
				[user],
			),
		},
		{
			value: 'marketer',
			title: 'Маркетинг',
			formatted: React.useCallback(
				(row: any) => {
					if (!!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.MARKETER) > -1) {
						if (row[`banner.statusMrkt`] === StatusEnum.pending) {
							return <OperationFrom row={row} init={load} postfix="Mrkt" />;
						}
					}
					return <div>{renderStatus('Mrkt', row, updateBanner, load, user)}</div>;
				},
				[user],
			),
		},
	];

	return (
		<div>
			<div className={styles.wrapper}>
				{passport && (
					<table>
						<tbody>
							<tr>
								<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
									<b>Месяц</b>
								</td>
								<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
									{new Date(passport?.placementAt).toLocaleDateString('ru-ru', { month: 'short', year: 'numeric' })}
								</td>
							</tr>
							<tr>
								<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
									<b>Контрагент</b>
								</td>
								<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
									{passport['counterparty.title']}
								</td>
							</tr>
							<tr>
								<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
									<b>Универмаг</b>
								</td>
								<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
									{passport['store.number']}
								</td>
							</tr>
							<tr>
								<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
									<b>Адрес универмага</b>
								</td>
								<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }} colSpan={2}>
									{passport['store.address']}
								</td>
							</tr>
							<tr>
								<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
									<b>ТЦ</b>
								</td>
								<td style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }} colSpan={2}>
									{passport['store.title']}
								</td>
							</tr>
						</tbody>
					</table>
				)}
				{<RequestUpToDateData storeId={passport['store.number']} />}
			</div>
			{data && (
				<div>
					{error && 'Error!'}
					{loading && 'Загрузка...'}

					<Table headers={headers} rows={data} />
				</div>
			)}
		</div>
	);
}
