import 'components/AdminLayout/scss/grid.global.scss';
// import 'components/AdminLayout/scss/text-blc.scss';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './config/keycloak';

import { useUnit } from 'effector-react';
import { $user } from 'models/user';
import { KeycloakTokenParsed } from 'keycloak-js';

import AppRouter from './AppRouter';
import { useNavigate } from 'react-router-dom';

const eventLogger = (event: unknown, error: unknown) => {
	console.log('onKeycloakEvent', event, error);
};

function App() {
	const user = useUnit($user);
	const navigate = useNavigate();

	const tokenLogger = (tokens: any) => {
		console.log('onKeycloakTokens', tokens);

		const tokenParsed: KeycloakTokenParsed = keycloak.tokenParsed!;

		console.log('APP Token Parsed:', {
			tokenParsed,
			user,
			if: tokenParsed && user && tokenParsed?.groups?.length !== user?.groups?.length,
		});

		if (tokenParsed && user && tokenParsed?.groups?.length !== user?.groups?.length) {
			const encodedRef = encodeURIComponent(location.pathname);
			navigate(`/login?ref=${encodedRef}`);
		}
	};

	console.log('App', { user });
	return (
		<ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger}>
			<AppRouter />
		</ReactKeycloakProvider>
	);
}

export default App;

// import { PublicClientApplication } from '@azure/msal-browser';
// import { MsalProvider } from '@azure/msal-react';
// import PageLayout from 'components/PageLayout';
// import { msalConfig } from './config/auth';
// import { LayoutContextProvider } from './LayoutContext';

// const msalInstance = new PublicClientApplication(msalConfig);

// function App() {
// 	return (
// 		<LayoutContextProvider>
// 			<MsalProvider instance={msalInstance}>
// 				<PageLayout />
// 			</MsalProvider>
// 		</LayoutContextProvider>
// 	);
// }

// export default App;
